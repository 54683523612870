<template>
    <div class="footer">
        <div class="footer-top">
            <div class="footer-top-info">
                <div class="footer-info">
                    <img src="../assets/dibu_icon_01.png" oncontextmenu="return false;" alt="" />
                    <div>{{ $t('zhuan-ye-ping-tai') }}</div>
                </div>
                <div class="footer-info">
                    <img src="../assets/dibu_icon_02.png" oncontextmenu="return false;" alt="" />
                    <div>{{ $t('zi-yuan-ji-qun-pang-da') }}</div>
                </div>
                <div class="footer-info">
                    <img src="../assets/dibu_icon_03.png" oncontextmenu="return false;" alt="" />
                    <div>{{ $t('yi-zhan-shi-fu-wu') }}</div>
                </div>
                <div class="footer-info">
                    <img src="../assets/dibu_icon_04.png" oncontextmenu="return false;" alt="" />
                    <div>{{ $t('xin-yu-bao-zhang') }}</div>
                </div>
                <div class="footer-info">
                    <img src="../assets/dibu_icon_05.png" oncontextmenu="return false;" alt="" />
                    <div>{{ $t('da-pin-pai-xin-lai') }}</div>
                </div>
            </div>
        </div>
        <div class="footer-center">
            <div class="footer-content">
                <div class="foot-item">
                    <router-link to="/IMT">{{ $t('imt-ping-tai') }}</router-link>
                    <router-link to="/IMT#service">{{ $t('wo-men-de-fu-wu') }}</router-link>
                    <router-link to="/IMT#connect">{{ $t('lian-xi-wo-men') }}</router-link>
                    <router-link to="/manufacturersEntering">{{ $t('ru-zhu-ping-tai') }}</router-link>
                </div>
                <div class="foot-item foot2">
                    <router-link to="/brand">{{ $t('zhan-shang-zong-lan') }}</router-link>
                    <router-link to="/product">{{ $t('quan-qiu-chan-pin') }}</router-link>
                    <router-link to="/NEWS">{{ $t('xin-xi-zi-xun') }}</router-link>
                </div>

                <div class="foot-item foot2">
                    <div class="link-list">
                        <router-link to="/rule?type=RULE_REGISTER">{{ $t('yong-hu-zhu-ce-xie-yi') }}</router-link>
                        <router-link to="/rule?type=RULE_PRIVACY">{{ $t('yin-si-zheng-ce') }}</router-link>
                    </div>
                </div>
                <div class="phone">
                    <div class="code">
                        <qrcode class="qrcode" :value="url" :margin="2" :width="80" />
                    </div>
                    <div class="textContent">
                        <div class="text-item">
                            <img src="../assets/footer_icon_dianhua.png" oncontextmenu="return false;" alt="" />
                            <span>86-21-60797117</span>
                        </div>
                        <div class="text-item">
                            <img src="../assets/footer_icon_youjian.png" oncontextmenu="return false;" alt="" />
                            <span>imttech@itoc.com.cn</span>
                        </div>
                        <div class="text-item">
                            <img src="../assets/footer_icon_dizhi.png" oncontextmenu="return false;" alt="" />
                            <span>{{
                                $t('zhong-guo-shang-hai-zi-you-mao-yi-shi-yan-qu-fu-te-dong-san-lu-526-hao')
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="../assets/nav_logo.png" alt="" />
                </div>
            </div>

            <div class="footer-tips">
                {{
                    $t(
                        'ye-mian-suo-she-ji-chan-pin-xin-xi-jin-yong-yu-zhan-shi-mu-de-ben-wang-zhan-ji-ben-wang-zhan-hui-yuan-he-zuo-fang-bao-liu-geng-gai-wang-ye-shang-suo-lie-chan-pin-xiang-guan-xin-xi-de-quan-li-shu-bu-ling-hang-tong-zhi'
                    )
                }}
            </div>
        </div>
        <div class="bottom">
            Copyright © 2021 {{ $t('shang-hai-zhi-yi-ke-ji-you-xian-gong-si') }}

            <el-link href="https://beian.miit.gov.cn/" target="_blank"> 沪ICP备2021000081号-1 </el-link>
            <el-link
                class="police"
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502016638"
            >
                <img src="http://s.bulejie.com/images/gabaicon.png" />
                <span> 沪公网安备 31011502016638号</span>
            </el-link>
        </div>
    </div>
</template>
<script>
import Qrcode from 'vue-qrcode';
export default {
    name: 'Footer',
    computed: {
        url() {
            return window.location.origin + '/index/home';
        }
    },
    components: {
        Qrcode
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';
.footer {
    background-color: #292c33;
    min-width: 100%;
}
.footer-center {
    @include center-content(360);
}

.footer-content {
    display: flex;
    padding: 38px 0 0;
}

.right {
    img {
        width: 204px;
        height: 72px;
        display: block;
    }
}
.foot-item + .foot-item {
    border-left: 1px solid #33363d;
}
.foot-item {
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: flex-start;
    width: 130px;

    &.foot2 {
        width: 168px;
        align-items: center;
    }

    .link-list {
        display: flex;
        flex-direction: column;
    }

    .el-link {
        font-size: 12px;
        color: #fff;
        font-weight: normal;

        &:hover {
            color: mix($--color-black, $--color-white, $--button-active-shade-percent);
            text-decoration: underline;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    a {
        font-size: 12px;
        color: #fff;
        font-weight: normal;

        &:hover {
            color: mix($--color-black, $--color-white, $--button-active-shade-percent);
            text-decoration: underline;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
    img {
        width: 84px;
        height: 60px;
        display: block;
    }

    .text1 {
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        line-height: 22px;
        margin-bottom: 12px;
    }

    .text2 {
        color: #fff;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        line-height: 17px;
        margin-top: 12px;
    }
}

.bottom {
    font-size: 12px;
    text-align: center;
    color: #ffffff60;
    line-height: 17px;
    padding: 10px 0;
    background-color: #1f2228;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-link {
        color: #ffffff60;
        font-size: 12px;
        line-height: 17px;
        margin-left: 15px;
        font-weight: normal;

        &:hover {
            color: #fff;
            text-decoration: underline;
        }

        &.police {
            display: flex;
            align-items: center;
            margin-left: 20px;
            img {
                width: 12px;
                height: 12px;
                display: block;
            }
        }
    }
}

.phone {
    display: flex;
    flex-grow: 1;
    margin-left: 75px;
    .code {
        width: 80px;
        height: 80px;
    }
    .textContent {
        flex-grow: 1;
        margin-left: 25px;
        padding: 5px 0;

        .text-item {
            display: flex;
            align-items: flex-start;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            img {
                width: 16px;
                height: 16px;
            }

            span {
                margin-left: 10px;

                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 18px;
                max-width: 160px;
            }
        }
    }
}

.footer-top {
    background-color: #e6e9f0;
    padding: 25px 0;

    .footer-top-info {
        @include center-content(452);
        display: flex;
        justify-content: space-between;

        .footer-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 42px;
                height: 42px;
                display: block;
            }

            font-size: 12px;
            color: #878d99;
            line-height: 17px;
            div {
                margin-top: 12px;
            }
        }
    }
}

.footer-tips {
    font-size: 12px;
    text-align: center;
    color: #565b66;
    line-height: 17px;
    padding: 25px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<style lang="scss">
.police {
    .el-link--inner {
        display: flex;
        align-items: center;
    }
}
</style>
