<template>
    <div>
        <el-upload
            class="single-upload"
            :action="uploadUrl"
            :headers="headers"
            :show-file-list="false"
            :on-success="onSuccess"
            :before-upload="beforeUpload"
            :style="uploadStyle"
            accept="image/*"
        >
            <slot name="main">
                <div class="wrapper">
                    <img v-if="imageUrl" :src="imageUrl" class="upload-image" :style="uploadStyle" />
                    <div v-else class="upload-default" :style="uploadStyle">
                        {{ $t('dian-ji-shang-chuan') }}
                    </div>
                    <div v-if="loading" class="loading">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
            </slot>
        </el-upload>

        <div class="tips">
            <slot name="tips"></slot>
        </div>
    </div>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        this.updateImageUrl(this.value);
    },
    props: {
        value: String,
        usePrefix: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        ratio: {
            type: Array,
            default: [1, 1]
        },
        imgWidth: {
            type: Number,
            default: 100
        },
        name: {
            type: String,
            default: ''
        },
        size: {
            tupe: Number,
            // 单位M
            default: 2
        }
    },
    data() {
        return {
            imageUrl: '',
            loading: false,
            uploadUrl: ''
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('imtPCtoken')
            };
        },
        uploadStyle() {
            return {
                width: this.imgWidth + 'px',
                height: (this.imgWidth * this.ratio[1]) / this.ratio[0] + 'px'
            };
        }
    },
    methods: {
        onSuccess(res, file) {
            this.loading = false;
            this.imageUrl = URL.createObjectURL(file.raw);
            var newVal = '';
            if (res instanceof Array) {
                newVal = res[0];
            } else {
                newVal = res;
            }
            this.$emit('input', newVal);
        },
        onError(err, file, fileList) {
            this.loading = false;
        },
        beforeUpload(file) {
            const isLt = file.size / 1024 / 1024 < this.size;

            if (!isLt) {
                this.$message.error(this.$t('shang-chuan-wen-jian-da-xiao-bu-neng-chao-guo') + this.size + ' MB!');
            }
            this.loading = isLt;
            return isLt;
        },
        updateImageUrl(url) {
            this.imageUrl = url;
        }
    },
    watch: {
        value(val) {
            this.updateImageUrl(val);
        }
    }
};
</script>
<style lang="scss" scoped>
.single-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fbfdff;

    &:hover {
        border-color: #ffa526;
    }
}

.upload-image {
    height: 178px;
    display: block;
    border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        border-color: #ffa526;
    }
}

.wrapper {
    position: relative;
}

.single-upload .el-upload {
    position: relative;
}

.single-upload {
    width: 178px;
    height: 178px;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    color: #333;
    font-size: 24px;
}

.upload-default {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f7fa;
    color: #bcc1cc;
    font-size: 12px;
    &:hover {
        border-color: #ffa526;
        color: #ffa526;
    }
}

.tips {
    font-size: 12px;
    font-weight: 400;
    color: #bcc1cc;
    line-height: 17px;
    margin-top: 12px;
}
</style>
<style lang="scss">
.avatar-cropper .avatar-cropper-close {
    font-size: 60px;
    padding: 100px;
}
.avatar-cropper .avatar-cropper-mark {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
