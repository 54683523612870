<template>
    <div :style="defaultStyle">
        <el-header height="90px" id="header" class="header" :style="{ left: 0 - left + 'px' }">
            <div class="header-main">
                <img class="logo" @click="$router.push('/home')" src="../../assets/nav_logo_02.png" alt="" />

                <div class="title">{{ $t(pageTitle) }}</div>

                <div class="tip"></div>

                <div class="connect">{{ $t('zi-xun-re-xian') }}：86-21-60797117</div>

                <div class="laguage-group">
                    <div class="language-item" @click="changeLocales('zh')" :class="{ active: isChinese }">中文</div>
                    <div class="language-item" @click="changeLocales('en')" :class="{ active: !isChinese }">
                        English
                    </div>
                </div>
            </div>
        </el-header>
    </div>
</template>
<script>
export default {
    name: 'Header',
    data() {
        return {
            defaultStyle: {
                height: '90px',
                backgroundColor: '#FFFFFF'
            },
            left: 0
        };
    },
    computed: {
        pageTitle() {
            return this.$route.meta.title;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollEvent);
    },
    methods: {
        scrollEvent() {
            this.left = document.documentElement.scrollLeft;
        },
        changeLocales(lang) {
            this.$i18n.locale = lang;
            window.sessionStorage.setItem('locale', lang);
            this.$emit('scrollEvent');
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.header {
    background-color: transparent;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    // width: 100%;
    transition: all ease-in-out 0.3s;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    padding: 0 50px;
    height: 90px;
    margin: 0 auto;
    min-width: 1200px;
}
.el-menu {
    background-color: transparent;
    margin-left: 30px;
}

.el-menu.el-menu--horizontal.menu {
    border-bottom-width: 0;
}

.tip {
    flex-grow: 1;
    min-width: 10px;
}

.logo {
    width: 170px;
    height: 60px;
    display: block;
    cursor: pointer;
}

.title {
    font-size: 24px;
    color: #000000;
    line-height: 50px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    position: relative;
    padding-left: 100px;
    font-weight: lighter;

    &::before {
        content: '';
        width: 1px;
        height: 50px;
        background: #f2f3f5;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 40px;
        top: 0px;
    }
}

.connect {
    font-size: 20px;
    font-weight: bold;
    color: #818999;
    line-height: 28px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);

    margin-right: 10%;
}

.laguage-group {
    display: flex;
    font-size: 12px;
    color: #0f264d;
    line-height: 17px;
    flex-shrink: 0;
    margin-right: 0.8rem;

    .language-item {
        cursor: pointer;
        position: relative;
        color: #bcc1cc;
        padding: 6px;

        &:not(:first-child) {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                background-color: #bcc1cc;
                width: 1px;
                height: 6px;
                top: 12px;
            }
        }

        &:hover {
            color: #0f264d88;
        }

        &.active {
            color: #0f264d;
            font-weight: bold;
        }
    }
}
</style>
