<template>
    <div class="order">
        <div class="top">
            <div class="title">{{ $t('xiao-xi-lie-biao') }}</div>
            <router-link to="/chat">
                <el-link type="info" :underline="false">{{ $t('cha-kan-quan-bu') }}</el-link></router-link
            >
        </div>

        <el-divider direction="horizontal" content-position="left"></el-divider>

        <div class="list scrollbar" v-loading="loading">
            <Message :info="emailInfo" type="system"></Message>
            <Message :info="item" :key="item.otherUserId" v-for="item in messageList"></Message>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Message from './Message';
export default {
    name: 'emailOrder',
    data() {
        return {
            activeName: '',
            page: 0,
            size: 10,
            list: [],
            last: false,
            loading: false,
            systemInfo: {}
        };
    },
    computed: {
        ...mapState(['userInfo', 'emailInfo', 'messageList']),
        empty() {
            return !this.loading && !this.emailInfo && this.messageList.length === 0;
        }
    },
    methods: {
        init() {
            this.getData();
        },
        getData() {
            this.loading = true;
            this.$store.dispatch('getChatList').then(res => {
                this.loading = false;
            });
        }
    },
    components: {
        Message
    }
};
</script>
<style lang="scss" scoped>
.order {
    padding: 0;
}

.list {
    height: 300px;
    overflow: auto;
    padding: 5px 0;
}

.el-divider {
    margin: 0 16px;
    width: auto;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .title {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        line-height: 20px;
    }

    .el-link {
        font-size: 12px;
    }
}
</style>
