//用户状态

import { mapState } from 'vuex';
export default {
    methods: {
        //添加对比
        addContrast(productId) {
            this.checkLogin()
                .then(() => {
                    return this.$http.post('/intentionList/add', {
                        productId: productId,
                        type: 'PRODUCT_COMPARISON'
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('tian-jia-cheng-gong'));
                    this.$store.dispatch('getContrast');
                })
                .catch(e => {
                    if (e.error === '已添加') {
                        this.$message.warning(this.$t('yi-zai-dui-bi-lie-biao-zhong'));
                    } else if (e.error) {
                        this.$alert(
                            this.$t('dang-qian-chan-pin-bu-shu-yu') +
                                '<span style="font-weight:bold;">【' +
                                this.getName(this.contrastCategory) +
                                '】</span>',
                            this.$t('tong-lei-bie-chan-pin-cai-neng-dui-bi'),
                            {
                                confirmButtonText: this.$t('que-ding'),
                                dangerouslyUseHTMLString: true,
                                center: true,
                                callback: action => {}
                            }
                        );
                    } else {
                        if (e === '未登录') {
                            this.login();
                        } else if (e && e.error) {
                            this.$message.warning(e.error);
                        }
                    }
                });
        },
        clearAllContrast() {
            this.$confirm(this.$t('que-ren-yao-qing-kong-quan-bu-chan-pin-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-qing-kong'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    return this.$http.post('/intentionList/delAll', {
                        type: 'PRODUCT_COMPARISON'
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('qing-kong-cheng-gong'));
                    this.$store.dispatch('getContrast');
                })
                .catch(() => {});
        },
        delContrast(id) {
            this.$confirm(this.$t('que-ren-yao-shan-chu-gai-shang-pin-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-shan-chu'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    return this.$http.post('/intentionList/delOne', {
                        type: 'PRODUCT_COMPARISON',
                        productId: id
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$store.dispatch('getContrast');
                })
                .catch(() => {});
        }
    },

    computed: {
        ...mapState(['contrastList', 'contrastCategory'])
    }
};
