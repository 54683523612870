<template>
    <div class="comparedOrder">
        <div class="top">
            <div class="name">{{ $t('yi-xiang-qing-dan') }}</div>

            <el-link type="info" v-if="intentionList.length > 0" @click="clearAllIntention">{{
                $t('qing-kong')
            }}</el-link>
        </div>

        <div class="list scrollbar">
            <el-checkbox-group v-model="checked" @change="checkChange">
                <el-checkbox v-for="item in intentionList" :label="item.id" :key="item.id">
                    <product :info="item" @delete="delIntention"></product>
                </el-checkbox>
            </el-checkbox-group>

            <empty-page
                v-if="intentionList.length === 0"
                src="http://oss.imttech.cn/case/kong_img_yixiangdan.png"
            >
                <span slot="text">{{ $t('dang-qian-zan-wu-yi-xiang-chan-pino') }}</span>
            </empty-page>
        </div>

        <div class="bottom" v-if="intentionList.length > 0">
            <div class="bottom-content">
                <el-checkbox class="checkAll" v-model="checkAll" @change="checkAllChange">{{
                    $t('quan-xuan')
                }}</el-checkbox>
                <el-button type="warning" @click="advisory(checkedIntentionList, 'list')">{{
                    $t('pi-liang-xun-jia')
                }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import product from '../product/RowMini';
import intention from '../../mixins/intention';
import UserStoreOperation from '../../mixins/UserStoreOperation';
export default {
    name: 'IntentionOrder',
    mixins: [intention, UserStoreOperation],
    data() {
        return {
            checked: [],
            checkAll: false
        };
    },
    computed: {
        checkedIntentionList() {
            return [...this.intentionList].filter(item => {
                return [...this.checked].includes(item.id);
            });
        }
    },
    components: {
        product
    },
    methods: {
        checkAllChange(checked) {
            if (checked) {
                this.checked = [...this.intentionList].map(item => {
                    return item.id;
                });
            } else {
                this.checked = [];
            }
        },
        checkChange(checked) {
            if (checked.length === this.intentionList.length) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.comparedOrder {
    position: relative;
    padding: 16px 6px;
}
.el-button {
    display: block;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #f2f3f5;

    .name {
        font-size: 14px;
        color: #292c33;
        font-weight: bold;
        line-height: 20px;
    }
}

.bottom {
    height: 66px;
}

.list {
    margin: 8px 0;
    height: 300px;
    padding: 0 10px;
    overflow: auto;
}

.checkAll {
    min-width: 88px;

    font-size: 12px;
    color: #878d99;
}
.el-checkbox {
    overflow: hidden;
}

.bottom-content {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 16px 16px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
}
</style>

<style lang="scss">
.comparedOrder {
    .list {
        position: relative;
        .el-checkbox {
            width: 100%;
            display: flex;
            align-items: center;

            .el-checkbox__label {
                flex-grow: 1;
                overflow: hidden;
            }
        }
    }
}
</style>
