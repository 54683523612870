<template>
    <div class="empty" :style="{ backgroundColor: bgColor }" :class="size">
        <!-- <i class="el-icon-receiving" v-if="!noLogo"></i> -->
        <div class="img">
            <slot name="img"> <img :src="src" /></slot>
        </div>

        <div class="text">
            <slot name="text">{{ $t('zan-shi-mei-you-shu-juo') }}</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        size: {
            type: String,
            default: 'meduim'
        },
        bgColor: {
            type: String,
            default: '#fff'
        },
        noLogo: {
            type: Boolean,
            default: false
        },
        src: {
            type: String,
            default: require('../assets/kong_img_default.png')
        }
    }
};
</script>

<style lang="scss" scoped>
.empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    .img {
        img {
            width: 240px;
            height: 240px;
            display: block;
        }
    }
}
.el-icon-receiving {
    font-size: 2rem;
    color: #bcc1cc;
}

.text {
    margin-top: 10px;

    font-size: 12px;
    color: #bcc1cc;
    line-height: 17px;
}

.empty.mini {
    .el-icon-receiving {
        font-size: 0.72rem;
    }
    .text {
        margin-top: 5px;
    }
}
</style>
