<template>
    <div id="app" :class="{ isEn: !isChinese }">
        <header-sys v-if="HeaderType === 'system'" />
        <app-header v-else-if="HeaderType !== 'none' && name" />
        <div id="main" class="el-main" :style="{ minHeight: HeaderType === 'none' ? '100vh' : 'calc(100vh - 90px )' }">
            <keep-alive :include="[...keepAlive]">
                <router-view class="appContainer" />
            </keep-alive>

            <app-footer v-if="FooterType !== 'none' && name" />
        </div>
        <Login ref="login" />

        <Robot />

        <RuleStep4 />

        <Consult />

        <el-dialog :title="tipInfo.desc" :visible.sync="showTips" center width="650px">
            <div class="dialog" v-html="tipInfo.value"></div>
        </el-dialog>
    </div>
</template>
<script>
import AppHeader from './components/header/Header';
import HeaderSys from './components/header/HeaderSys';
import AppFooter from './components/Footer';
import { mapState } from 'vuex';
import Login from './views/Login.vue';
import Robot from './views/system/Robot';
import RuleStep4 from './views/system/RuleStep4';
import Consult from './views/store/Consult';
export default {
    data() {
        return {
            loginVisible: true,
            tipInfo: {
                desc: '',
                value: ''
            },
            showTips: false
        };
    },
    metaInfo() {
        return {
            title: this.$t(this.pageTitle)
        };
    },
    components: { AppHeader, AppFooter, Login, HeaderSys, Robot, RuleStep4, Consult },
    computed: {
        ...mapState(['showLogin', 'keepAlive']),
        pageTitle() {
            return this.$route.meta.title;
        },
        HeaderType() {
            return this.$route.meta.HeaderType || 'default';
        },
        FooterType() {
            return this.$route.meta.FooterType || 'default';
        },
        name() {
            return this.$route.name || '';
        }
    },
    watch: {
        showLogin() {
            if (this.showLogin) {
                this.$refs.login.loginVisible = true;
            }
        }
    },
    created() {
        if (this.showLogin) {
            this.$refs.login.loginVisible = true;
        }
        console.log(process.env.NODE_ENV);
        if (this.nodeEnv !== 'production') {
            this.$i18n.locale = 'en';
        }

        if (window.sessionStorage.getItem('locale')) {
            this.$i18n.locale = window.sessionStorage.getItem('locale');
        }

        this.$nextTick(() => {
            if (!window.localStorage.getItem('nameChange')) {
                window.localStorage.setItem('nameChange', 'true');
                this.$http.get(`/sysConfig/get/${this.isChinese ? 'CHECK_RULE' : 'CHECK_RULE_EN'}`).then(res => {
                    this.tipInfo = res;
                    this.showTips = true;
                });
            }
        });
    },
    mounted() {
        window.addEventListener('resize', this.resize);
        this.$nextTick(() => {
            this.resize();
        });
        this.$store.dispatch('getAdminId');
        this.$store.dispatch('getArea');
        this.$http
            .get((process.env.NODE_ENV === 'development' ? 'https:' : 'https:') + '//oss.imttech.cn/utils/areaZh.json')
            .then(res => {
                this.$i18n.mergeLocaleMessage('zh', { ...res });
            });

        this.$http
            .get((process.env.NODE_ENV === 'development' ? 'https:' : 'https:') + '//oss.imttech.cn/utils/areaEn.json')
            .then(res => {
                this.$i18n.mergeLocaleMessage('en', { ...res });
            });
    },
    methods: {
        resize() {
            var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
            if (deviceWidth >= 1920) {
                deviceWidth = 1920;
            }
            if (deviceWidth <= 1200) {
                deviceWidth = 1200;
            }
            this.$store.commit('updateScale', deviceWidth / 1920);
            document.documentElement.style.fontSize = ((deviceWidth * 100) / 1920).toFixed(2) + 'px';
        }
    }
};
</script>
<style lang="sass" scoped>
@import './element-variables.scss'
#app
    width: 100%
    min-width: 1200px
    position: relative
    box-sizing: border-box

.appContainer
    width: 100%
    overflow: hidden
    background-color: $--background-color-base

.el-main
    padding: 0px!important
    height: 100%
    overflow: visible
    z-index: 1
    position: relative
</style>
