<template>
    <div style="height: 100%;">
        <div
            @click="goPath"
            class="bg autoImg"
            :style="{
                backgroundImage: 'url(' + src + ')',
                paddingTop: mul || 0,
                height: mul ? 0 : '100%',
                cursor: path ? 'pointer' : 'inherit'
            }"
        ></div>
    </div>
</template>
<script>
export default {
    name: 'AutoImg',
    props: {
        src: {
            type: String,
            default: ''
        },
        mul: {
            type: String,
            default: ''
        },
        path: {
            type: String,
            default: ''
        }
    },
    methods: {
        goPath() {
            // console.log(this.path);

            if (this.path) {
                this.$router.push(this.path);
            } else {
                this.$emit('imgClick');
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.autoImg {
    width: 100%;
    height: 0;
    cursor: pointer;
}
</style>
