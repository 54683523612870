import { userIdentity } from '../utils/AppState';
const observerMap = new Map();

export default {
    data() {
        return {
            nodeEnv: 'production'

            // nodeEnv: 'development'
        };
    },
    directives: {
        siwperPagination: {
            bind(el, binding) {
                const config = { attributes: false, childList: true, subtree: false };
                const callback = function (mutationsList, observer) {
                    if (el.children.length <= 1) {
                        el.style.visibility = 'hidden';
                    } else {
                        el.style.visibility = 'visible';
                    }
                };

                var _observer = new MutationObserver(callback);
                _observer.observe(el, config);
                observerMap.set(el, _observer);
            },
            unbind(el) {
                observerMap.get(el).disconnect();
            }
        }
    },
    computed: {
        isVendor() {
            if (
                this.$store.state.userInfo &&
                this.$store.state.buyersInfo &&
                this.$store.state.buyersInfo.status === 'PASS'
            ) {
                return true;
            } else {
                return false;
            }
        },
        isChinese() {
            if (this.$i18n.locale === 'zh') {
                return true;
            } else {
                return false;
            }
        },
        isVip() {
            return true;
        },
        adminPath() {
            return this.isVendor ? '/vendorDatas' : '/mineVendorInfo';
        }
    },
    methods: {
        getName(info, labels = ['chName', 'enName'], isFalse = false) {
            if (!info || !info.hasOwnProperty(labels[0])) {
                return '';
            } else if (
                (this.$i18n.locale === 'zh' && !isFalse) ||
                (this.$i18n.locale !== 'zh' && isFalse) ||
                !info.hasOwnProperty(labels[1])
            ) {
                return info[labels[0]];
            } else {
                return info[labels[1]] || info[labels[0]];
            }
        },
        login() {
            this.$confirm(this.$t('yong-hu-wei-deng-lu-shi-fou-li-ji-deng-lu'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-deng-lu'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    this.$store.commit('updateShowLogin', true);
                })
                .catch(() => {});
        },
        logout() {
            this.$confirm(this.$t('que-ren-yao-tui-chu-dang-qian-zhang-hao-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-tui-chu'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    window.localStorage.removeItem('imtPCtoken');
                    location.reload();
                })
                .catch(() => {});
        },
        checkLogin() {
            if (this.$store.state.userInfo) {
                console.log('已登录');
                return Promise.resolve(this.$store.state.userInfo);
            } else {
                return Promise.reject('未登录');
            }
        },
        nullFormat(row, column, cellValue, index) {
            return cellValue || '-';
        },
        getImport(name, search) {
            if ((search || this.search) && name) {
                var searchs = (search || this.search).toLowerCase().split('');
                var names = name.split('');
                return names
                    .map(item => {
                        return searchs.includes(item.toLowerCase())
                            ? `<span style='color:#ffa526'>${item}</span>`
                            : item;
                    })
                    .join('');
            } else {
                return name;
            }
        },
        getList(info, next = '', index = 0) {
            let img = '';
            if (info instanceof Array && info.length > index) {
                img = [...info][index];
            } else if (typeof info === 'string' && info) {
                img = info.split(',')[index];
            }
            return img + next;
        }
    }
};
