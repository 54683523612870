<template>
    <div class="comparedOrder">
        <div class="top">
            <div class="name">{{ $t('chan-pin-dui-bi-dan') }}</div>

            <el-link type="info" v-if="contrastList.length > 0" @click="clearAllContrast">{{
                $t('qing-kong')
            }}</el-link>
        </div>
        <div class="top-btn">
            <el-button type="warning" plain size="small" v-if="contrastList.length > 0">
                {{ $t('dang-qian-lei-bie') }}<br v-if="!isChinese" />{{ getName(contrastCategory) }}
            </el-button>
        </div>

        <div class="list scrollbar">
            <product v-for="item in contrastList" :key="item.id" :info="item" @delete="delContrast"></product>
            <empty-page
                v-if="contrastList.length === 0"
                src="http://oss.imttech.cn/case/kong_img_duibi.png"
            >
                <span slot="text">{{ $t('dang-qian-zan-wu-ke-dui-bi-chan-pino') }}</span>
            </empty-page>
        </div>

        <div class="bottom" v-if="contrastList.length > 0">
            <div class="bottom-content">
                <el-button type="warning" @click="$router.push('/compare')">{{ $t('dui-bi') }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import product from '../product/RowMini';
import contrast from '../../mixins/contrast';
export default {
    name: 'comparedOrder',
    data() {
        return {};
    },
    mixins: [contrast],
    components: {
        product
    }
};
</script>
<style lang="scss" scoped>
.comparedOrder {
    position: relative;
    padding: 16px 6px;
}
.el-button {
    display: block;
    width: 100%;
}

.top-btn {
    padding: 0 10px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;

    .name {
        font-size: 14px;
        color: #292c33;
        font-weight: bold;
        line-height: 20px;
    }
}

.bottom {
    height: 66px;
}

.list {
    margin: 8px 0;
    height: 300px;
    padding: 0 10px;
    overflow: auto;
}

.bottom-content {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 16px 16px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
