import Vue from 'vue';
import Vuex from 'vuex';
import http from '../plugins/http';
import { Message } from 'element-ui';
import router from '../router';
import { getTreeByKey } from '../utils/Array';
import VueI18n from 'vue-i18n';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        showLogin: false,
        intentionList: [],
        contrastList: [],
        contrastCategory: {},
        readInfo: {
            message: false,
            email: false
        },
        scale: 1,
        vendorInfo: null,
        showSuccess: false,
        advisoryForm: {
            show: false,
            vendorInfoId: 0,
            chooseId: '',
            offlineExperience: false
        },
        vipInfo: null,
        adminId: 0,
        keepAlive: [],
        areaList: [],
        buyersInfo: null,
        emailInfo: {},
        messageList: []
    },
    mutations: {
        updateShowLogin(state, show) {
            state.showLogin = show;
        },
        updateUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        updateIntentionList(state, intentionList) {
            state.intentionList = intentionList;
        },
        updateContrastInfo(state, contrast) {
            state.contrastList = contrast.product;
            state.contrastCategory = contrast.category;
        },
        updateReadInfo(state, info) {
            state.readInfo = {
                ...state.readInfo,
                ...info
            };
        },
        updateScale(state, scale) {
            state.scale = scale;
        },
        updateVendorInfo(state, info) {
            state.vendorInfo = info;
        },
        updateShowSuccess(state, show) {
            state.showSuccess = show;
        },
        updateAdvisoryForm(state, info) {
            state.advisoryForm = info;
        },
        updateVipInfo(state, info) {
            state.vipInfo = info;
        },
        updateAdminId(state, id) {
            state.adminId = id;
        },
        updateKeepAlive(state, keepAlive) {
            state.keepAlive = keepAlive;
        },
        updateAreaList(state, areaList) {
            state.areaList = areaList;
        },
        updateBuyersInfo(state, buyersInfo) {
            state.buyersInfo = buyersInfo;
        },
        updateEmailInfo(state, emailInfo) {
            state.emailInfo = emailInfo;
        },
        updateMessageList(state, messageList) {
            state.messageList = messageList;
        }
    },
    actions: {
        getAdminId(context) {
            http.axios.post('/user/getAdmin').then(res => {
                context.commit('updateAdminId', res);
            });
        },
        getUserInfo(context) {
            return http.axios.get('/user/my').then(res => {
                let info = res.authorities.find(item => {
                    return item.name === 'ROLE_ADMIN';
                });
                if (info) {
                    window.localStorage.setItem('imtPCtoken', '');
                    return Promise.reject({
                        error: '用户名密码错误'
                    });
                }
                context.commit('updateUserInfo', res);
                context.dispatch('userInit');
                return context.dispatch('getBuyInfo');
                // return Promise.resolve();this.$store.dispatch('getBuyInfo')
            });
        },
        getVipInfo(context) {
            return http.axios
                .get('/vendorMember/my', {
                    vendorId: context.state.buyersInfo.vendorId
                })
                .then(res => {
                    context.commit('updateVipInfo', res || null);
                    return Promise.resolve();
                });
        },
        getVendorInfo(context) {
            return http.axios
                .post(
                    '/vendorInfo/all',
                    {
                        query: {
                            userId: context.state.userInfo.id
                        }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    if (res.totalElements > 0) {
                        context.commit('updateVendorInfo', res.content[0]);

                        const vendorInfoStatus = window.localStorage.getItem(`saveVendorInfo_${res.content[0].id}`);
                        if (vendorInfoStatus !== res.content[0].status) {
                            window.localStorage.setItem(`saveVendorInfo_${res.content[0].id}`, res.content[0].status);

                            if (res.content[0].status === 'PASS') {
                                context.commit('updateShowSuccess', true);
                            }
                        }
                        return context.dispatch('getVipInfo');
                    } else {
                        context.commit('updateVendorInfo', null);
                    }
                    return Promise.resolve();
                })
                .then(res => {
                    return Promise.resolve();
                });
        },
        getBuyInfo(context) {
            return http.axios
                .post('/buyersInfo/getInfo', {
                    userId: context.state.userInfo.id
                })
                .then(res => {
                    context.commit('updateBuyersInfo', res || null);
                    if (res.vendorId) {
                        context.dispatch('getVipInfo');
                    }
                    return Promise.resolve(res);
                });
        },
        getContrast(context) {
            if (!context.state.userInfo) {
                context.commit('updateContrastInfo', {
                    product: [],
                    category: ''
                });
            } else {
                http.axios.get('/intentionList/myCompared').then(res => {
                    context.commit(
                        'updateContrastInfo',
                        res || {
                            product: [],
                            category: ''
                        }
                    );
                });
            }
        },
        getIntentionList(context) {
            if (!context.state.userInfo) {
                context.commit('updateIntentionList', []);
            } else {
                http.axios.get('/intentionList/myIntention').then(res => {
                    context.commit('updateIntentionList', res || []);
                });
            }
        },
        getChatList(context) {
            return Promise.allSettled([http.axios.get('/email/my'), http.axios.post('/message/my')]).then(res => {
                res.forEach((item, index) => {
                    if (index === 0) {
                        context.commit('updateEmailInfo', item.value || {});
                    } else {
                        context.commit(
                            'updateMessageList',
                            (item.value || [])
                                .filter(item => {
                                    return !!item.otherName;
                                })
                                .sort((a, b) => {
                                    return a.time - b.time;
                                })
                        );
                    }
                });

                return Promise.resolve();
            });
        },
        userInit(context) {
            console.log('刷新聊天数据');
            context.dispatch('getContrast');
            context.dispatch('getIntentionList');
            context.dispatch('getChatList');
        },
        getArea(context) {
            http.axios
                .get(
                    (process.env.NODE_ENV === 'development' ? 'https:' : 'https:') +
                        '//oss.imttech.cn/utils/area.json'
                )
                .then(res => {
                    context.commit('updateAreaList', getTreeByKey(res, 4));
                });
        }
    }
});
