import { sexModels, languageModels, nameModels } from '../utils/AppState';
import { sendMsg, regular, countDown } from '../utils/Sms';

export default {
    data() {
        return {
            languageModels,
            rules: {
                phone: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-shou-ji-hao-ma')));
                            }
                        }
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (regular.test(value)) {
                                callback();
                            } else {
                                callback(new Error(this.$t('shou-ji-hao-ge-shi-cuo-wu')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('qing-shu-ru-duan-xin-yan-zheng-ma'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('mi-ma-bu-neng-wei-kong'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                password2: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('mi-ma-bu-neng-wei-kong'));
                            }
                        },
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value === this.form.password) {
                                callback();
                            } else {
                                callback(new Error(this.$t('liang-ci-mi-ma-bu-yi-zhi')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                // card: [
                //     {
                //         required: true,
                //         message: $t('zheng-jian-tu-pian-bu-neng-wei-kong'),
                //         trigger: 'blur'
                //     }
                // ],
                // nickname: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (value) {
                //                 callback();
                //             } else {
                //                 callback(this.$t('lian-xi-ren-xing-ming-bu-neng-wei-kong'));
                //             }
                //         },
                //         trigger: 'blur'
                //     }
                // ],
                // sex: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (value) {
                //                 callback();
                //             } else {
                //                 callback(this.$t('qing-xuan-ze-cheng-hu'));
                //             }
                //         },
                //         trigger: 'blur'
                //     }
                // ],
                email: [
                    // {
                    //     required: true,
                    //     validator: (rule, value, callback) => {
                    //         if (value) {
                    //             callback();
                    //         } else {
                    //             callback(this.$t('you-xiang-bu-neng-wei-kong'));
                    //         }
                    //     },
                    //     trigger: 'blur'
                    // },
                    {
                        validator: (rule, value, callback) => {
                            if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error(this.$t('you-xiang-ge-shi-cuo-wu')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                chCompanyName: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            console.log(value);
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('gong-si-ji-gou-yuan-xiao-ming-cheng-bu-neng-wei-kong'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                // position: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             console.log(value);
                //             if (value) {
                //                 callback();
                //             } else {
                //                 callback(this.$t('zhi-wei-bu-neng-wei-kong'));
                //             }
                //         },
                //         trigger: 'blur'
                //     }
                // ]
            },
            rulesRequire: {
                required: true,
                validator: (rule, value, callback) => {
                    console.log(value);
                    console.log(rule);
                    if (value) {
                        callback();
                    } else {
                        callback(' ');
                    }
                },
                trigger: 'blur'
            },
            isSend: false,
            senNum: 60,
            productCategorys: [],
            applications: [],
            loading: false
        };
    },
    computed: {
        sexModels() {
            return [...sexModels].map(item => {
                return [item[0], { label: this.$t(item[1].label) }];
            });
        }
    },
    methods: {
        getImgInfo(e) {
            this.$http
                .post('/auth/businessCard', {
                    url: e
                })
                .then(res => {
                    const result = res.words_result || {};
                    this.form.nickname = result.NAME[0];
                    this.form.email = result.EMAIL[0];
                    this.form.chCompanyName = result.COMPANY[0];
                    this.form.position = result.TITLE[0];
                });
        },
        sendMsg() {
            if (this.isSend) {
                return;
            }
            this.$refs.form.validateField('phone', res => {
                if (!res) {
                    sendMsg(this.form.phone, !this.isChinese).then(res => {
                        this.isSend = true;
                        this.senNum = 61;
                        this.setTime();
                    });
                }
            });
        },
        setTime() {
            this.senNum = countDown(this.senNum);
            if (this.senNum === null) {
                this.isSend = false;
                this.senNum = 60;
            } else {
                setTimeout(this.setTime, 1000);
            }
        },
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    this.$message.warning(this.$t('you-bi-tian-xiang-zan-wei-tian-xie-huo-bu-fu-he-gui-fan'));
                    return false;
                }
            });
        },
        getProducts() {
            //产品类型列表
            // this.$http.get('/productCategory/allList').then(res => {
            //     this.productCategorys = res;
            // });
        },
        getApplication() {
            //应用邻域列表
            this.$http
                .get('/productTag/findByName', {
                    enName: 'application'
                })
                .then(res => {
                    this.applications = res;
                });
        },
        getRule(name, type = 'blur', isArray = false) {
            return {
                required: true,
                validator: (rule, value, callback) => {
                    if ((value && value.toString() !== '') || value === false) {
                        callback();
                    } else {
                        callback(name || ' ');
                    }
                },
                trigger: type
            };
        }
    }
};
