const delChild = (list = [], nextKey = 'children', loop = true) => {
    return list.map(item => {
        const info = {
            ...item,
            [nextKey]: item[nextKey] && item[nextKey].length > 0 ? delChild(item[nextKey], nextKey) : null
        };
        return info;
    });
};

function getFlatByKey(list = [], key = 'name', preText = '', nextkey = 'children', loop = true) {
    const __list = list.map(item => {
        const info = { ...item };
        delete info.children;
        const _list = [
            {
                ...info,
                [key]: info[key]
            }
        ];

        if (item[nextkey]) {
            _list.push(getFlatByKey(item[nextkey], key, `${item[key]}/`));
        }

        return _list;
    });

    return __list.flat(Infinity);
}

function getOne(list = []) {
    if (list && list.length > 0) {
        return list[0];
    } else {
        return '';
    }
}

function flatChildren(list) {
    return list
        .map(item => {
            if (item.children) {
                return [item, flatChildren(item.children)];
            }
            return [item];
        })
        .flat(Infinity);
}

function getFather(id, list) {
    let _list = [];
    const _info = list.find(item => {
        return item.id === id;
    });
    _list.push(_info);
    if (_info.parentId) {
        _list = [..._list, ...getFather(_info.parentId, list)];
    }
    return _list;
}

function getArray(list, num) {
    if (num == 1) {
        return list
            .map(item => {
                if (item.children) {
                    return [{ ...item, children: null }];
                } else {
                    return [{ ...item, children: null }];
                }
            })
            .flat(Infinity);
    } else {
        return list
            .map(item => {
                if (item.children) {
                    return [{ ...item }, getArray(item.children, item.value, num - 1)];
                } else {
                    return [{ ...item }];
                }
            })
            .flat(Infinity);
    }
}

function treeBackLabel(
    tree,
    labelFuction = (info, labelName = 'label') => {
        return info[labelName];
    },
    labelName = 'label'
) {
    return tree.map(item => {
        if (item.children && item.children.length >= 0) {
            return {
                ...item,
                children: treeBackLabel(item.children, labelFuction, labelName),
                [labelName]: labelFuction(item, labelName)
            };
        } else {
            return { ...item, children: null, [labelName]: labelFuction(item, labelName) };
        }
    });
}

function getTreeByKey(list = [], num = 1) {
    if (num == 1) {
        return list.map(item => {
            return { ...item, children: null };
        });
    } else {
        return list.map(item => {
            if (item.children && item.children.length >= 0) {
                return {
                    ...item,
                    children: getTreeByKey(item.children, num - 1)
                };
            } else {
                return { ...item, children: null };
            }
        });
    }
}

function getInfoByKey(list = [], value, key = 'id') {
    let _info = list.find(item => {
        return item[key] === value;
    });

    if (!_info) {
        for (var i = 0; i < list.length; i++) {
            if (list[i].children && list[i].children.length > 0) {
                _info = getInfoByKey(list[i].children, value);
                if (_info) {
                    break;
                }
            }
        }
    }

    return _info;
}

export { delChild, getFlatByKey, getOne, flatChildren, getFather, getArray, getTreeByKey, getInfoByKey, treeBackLabel };
