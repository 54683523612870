<template>
    <div @click.stop="" class="search-box" :style="{ width: showSelect ? '280px' : '200px' }">
        <el-autocomplete
            :placeholder="showSelect ? $t('qing-shu-ru-ni-xiang-yao-sou-suo-de-guan-jian-ci') : $t('sou-suo')"
            suffix-icon="el-icon-search"
            v-model="searchVal"
            size="mini"
            class="seacrhCom"
            :trigger-on-focus="false"
            @focus="showSelect = true"
            @select="handleSelect"
            ref="input"
            :fetch-suggestions="querySearch"
            popper-class="my-seacrhCom-autocomplete"
        >
            <el-select
                @focus="showSelect = true"
                v-if="showSelect"
                v-model="searchType"
                slot="prepend"
                :placeholder="$t('qing-xuan-ze')"
                :popper-append-to-body="false"
            >
                <el-option
                    v-for="item in searchTypes"
                    :label="$i18n.t(item.name)"
                    :value="item.value"
                    :key="item.value"
                ></el-option>
            </el-select>
            <template slot-scope="{ item }">
                <div class="name" v-html="getImport(item, searchVal)"></div>
            </template>
        </el-autocomplete>

        <el-button @click="goSearch" icon="el-icon-search" type="text" class="btn1"> </el-button>
    </div>
</template>
<script>
import { searchType } from '../utils/AppState';
import { matchingName } from '../utils/CommonApi';
export default {
    name: 'Search',
    data() {
        return {
            searchVal: '',
            searchType: 'product',
            searchTypes: [...searchType].map(item => {
                return {
                    value: item[0],
                    name: item[1]
                };
            }),
            showSelect: false,
            restaurants: [],
            timeout: null
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('click', this.bulr);
            if (this.$refs.input.$el.getElementsByTagName('input').length > 0) {
                this.$refs.input.$el.getElementsByTagName('input')[0].addEventListener('keydown', this.enterINput);
            }
            if (this.$refs.input.$el.getElementsByClassName('el-icon-search').length > 0) {
                this.$refs.input.$el
                    .getElementsByClassName('el-icon-search')[0]
                    .addEventListener('click', this.goSearch);
            }
        });
    },
    methods: {
        bulr() {
            if (this.showSelect) {
                this.showSelect = false;
            }
        },
        querySearch(queryString, cb) {
            console.log(queryString);
            var results = [];
            if (queryString) {
                matchingName(queryString, this.searchType).then(res => {
                    let searchstr = queryString.toLowerCase();
                    results = [
                        ...new Set(
                            res.map(item => {
                                if (
                                    (this.isChinese && item.chName.toLowerCase().indexOf(searchstr) !== -1) ||
                                    (!this.isChinese && item.enName.toLowerCase().indexOf(searchstr) === -1)
                                ) {
                                    return item.chName;
                                } else {
                                    return item.enName;
                                }
                            })
                        )
                    ];

                    cb(results);
                });
            } else {
                cb(results);
            }
        },
        goSearch() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$router.push({
                        name: 'search',
                        query: {
                            search: this.searchVal,
                            searchType: this.searchType
                        }
                    });
                }, 100);
            });
        },
        handleSelect(item) {
            this.searchVal = item;
            this.goSearch();
        },
        enterINput(e) {
            if (e.key === 'Enter') {
                this.goSearch();
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('click', this.bulr);
        if (this.$refs.input.$el.getElementsByTagName('input').length > 0) {
            this.$refs.input.$el.getElementsByTagName('input')[0].removeEventListener('keydown', this.enterINput);
        }
        if (this.$refs.input.$el.getElementsByClassName('el-icon-search').length > 0) {
            this.$refs.input.$el
                .getElementsByClassName('el-icon-search')[0]
                .removeEventListener('click', this.goSearch);
        }
    }
};
</script>
<style lang="scss" scoped>
.search-box {
    background: rgba(255, 255, 255, 0.12);
    transition: width ease-in-out 0.3s;
}
.seacrhCom {
    height: 36px;
    box-sizing: border-box;
    width: 100%;
    display: block;
}

.btn1 {
    color: #c0c4cc;
    width: 36px;
    display: none;
    padding: 0;
    text-align: center;
    line-height: 36px;
    &:hover {
        color: #fff;
    }
}
@media screen and (max-width: 1400px) {
    .seacrhCom {
        display: none;
    }
    .btn1 {
        display: block;
    }
    .search-box {
        width: 36px !important;
    }
}
</style>

<style lang="scss">
.seacrhCom {
    // background: rgba(255, 255, 255, 0.12);
    // padding: 0 10px;
    line-height: 36px;
    position: relative;
    .el-input {
        display: flex;
        .el-input__inner {
            background-color: transparent;
            border-width: 0;
            border-radius: 0;
            color: #fff;
            padding: 0 30px 0 10px;
            font-size: 12px;
            height: 36px !important;
            flex-grow: 1;
        }
    }
    .el-input-group__prepend {
        background-color: transparent;
        border-width: 0;
        border-radius: 0;
        flex-shrink: 0;
        min-width: 30px;
        // box-sizing: border-box;
        // display: none;
        // &::after {
        //     content: '';
        //     width: 1px;
        //     background-color: rgba(255, 255, 255, 0.9) ;
        //     position: absolute;
        //     top: 8px;
        //     bottom: 8px;
        //     right: 8px;
        // }
    }
    .el-select {
        margin: 0 -0.2rem !important;
        padding: 0 0.1rem;

        .el-input--suffix {
            min-width: 0.5rem;
            padding-right: 0.1rem;
            box-sizing: content-box;
            // padding: 0px 10px 0 0px;
            display: flex;
            align-items: center;
            .el-input__inner {
                padding: 0;
                font-size: 0.12rem;
                color: rgba(255, 255, 255, 0.9) !important;
            }
            .el-input__suffix {
                color: rgba(255, 255, 255, 0.9) !important;
                font-size: 0.12rem;
            }
        }
    }

    .el-input__suffix {
        display: flex;
        align-items: center;
        z-index: 2;
        cursor: pointer;
    }

    .el-input__icon {
        line-height: 0.36rem !important;
    }
}
.el-scrollbar__wrap {
    .el-select-dropdown__item {
        padding: 0 0.12rem;
    }
}

.seacrhCom .el-select .el-input--suffix .el-input__inner {
    transform: translateX(-0.1rem);
    text-align: center;
}

.my-seacrhCom-autocomplete {
    .name {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
