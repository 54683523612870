import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import http from './plugins/http';
import store from './store';
import Element from 'element-ui';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import en from './locales/en.json';
import zh from './locales/zh.json';
// import VueScrollAnimate from 'vue-scroll-animate';
import 'animate.css/animate.compat.css';
import 'swiper/css/swiper.css';
import './element-variables.scss';
import TitleDriver from './components/TitleDriver';
import AutoImg from './components/AutoImg';
import CropUpload from './components/CropUpload';
import EmptyPage from './components/EmptyPage';
import FileUpload from './components/FileUpload';
import MultiUpload from './components/MultiUpload';
import SingleUpload from './components/SingleUpload';
import RichText from './components/RichText';
import VideoUpload from './components/VideoUpload';

import comment from './mixins/comment';
import MetaInfo from 'vue-meta-info';

// Vue.use(SocialShare);

Vue.component('title-driver', TitleDriver);
Vue.component('auto-img', AutoImg);
Vue.component('crop-upload', CropUpload);
Vue.component('file-upload', FileUpload);
Vue.component('empty-page', EmptyPage);
Vue.component('multi-upload', MultiUpload);
Vue.component('rich-text', RichText);
Vue.component('single-upload', SingleUpload);
Vue.component('video-upload', VideoUpload);
// Vue.component('share', Share);

Vue.use(VueI18n);
Vue.use(http);

Vue.use(MetaInfo);

Vue.mixin(comment);

const messages = {
    en: {
        ...en,
        ...enLocale
    },
    zh: {
        ...zh,
        ...zhLocale
    }
};

const i18n = new VueI18n({
    locale: 'zh', // set locale
    messages // set locale messages
});

Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
