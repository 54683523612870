const sexModels = new Map([
    [
        'male',
        {
            label: 'xian-sheng',
            zh: '男'
        }
    ],
    [
        'female',
        {
            label: 'nv-shi',
            zh: '女'
        }
    ]
]);
const boolModels = new Map([
    [true, 'shi'],
    [false, 'fou']
]);

const languageModels = new Map([
    ['ch', 'zhong-wen'],
    ['en', 'ying-wen'],
    ['both', 'zhong-ying'],
    ['other', 'qi-ta']
]);
const phonepre = new Map([['+86', 'CH +86']]);

const InformationType = new Map([
    [
        'INDUSTRY_NEWS,POLICY_INFORMATION,INDUSTRY_ACTIVITIES',
        {
            name: 'hang-ye-xin-wen',
            type: 'row'
        }
    ],
    [
        'NEW_PRODUCT_RELEASE',
        {
            name: 'xin-pin-fa-bu',
            type: 'gride'
        }
    ],
    [
        'TECHNICAL_INFORMATION',
        {
            name: 'ji-shu-zi-xun',
            type: 'row'
        }
    ]
    // [
    //     'POLICY_INFORMATION',
    //     {
    //         name: 'zheng-ce-xin-xi',
    //         type: 'row'
    //     }
    // ],
    // [
    //     'INDUSTRY_ACTIVITIES',
    //     {
    //         name: 'hang-ye-huo-dong',
    //         type: 'row'
    //     }
    // ]
]);

const InformationTypeEdit = new Map([
    [
        'INDUSTRY_NEWS',
        {
            name: 'hang-ye-xin-wen',
            type: 'row'
        }
    ],
    [
        'NEW_PRODUCT_RELEASE',
        {
            name: 'xin-pin-fa-bu',
            type: 'gride'
        }
    ],
    [
        'TECHNICAL_INFORMATION',
        {
            name: 'ji-shu-zi-xun',
            type: 'row'
        }
    ]
    // [
    //     'POLICY_INFORMATION',
    //     {
    //         name: 'zheng-ce-xin-xi',
    //         type: 'row'
    //     }
    // ],
    // [
    //     'INDUSTRY_ACTIVITIES',
    //     {
    //         name: 'hang-ye-huo-dong',
    //         type: 'row'
    //     }
    // ]
]);
const EnterpriseType = new Map([
    ['MANUFACTURER', 'sheng-chan-shang'],
    ['DEALER', 'jing-xiao-shang'],
    ['TECHNICAL_SERVICE_PROVIDER', 'ji-shu-fu-wu-shang']
]);

const BrandSortType = new Map([
    ['COMPOSITE', 'zong-he'],
    ['POPULARITY', 'ren-qi'],
    ['RECOMMEND', 'vip-tui-jian-qi-ye']
]);

const ProductSortType = new Map([
    ['COMPOSITE', 'zong-he'],
    ['POPULARITY', 'ren-qi'],
    [
        'TIME',
        {
            name: 'fa-bu-shi-jian',
            list: ['TIME', 'TIME_SEQUENCE']
        }
    ]
]);

const listType = new Map([
    ['gride', { name: 'da-tu', icon: '&#xe602;' }],
    ['row', { name: 'lie-biao', icon: '&#xe601;' }]
]);

const searchType = new Map([
    ['product', 'chan-pin'],
    ['brand', 'chang-shang'],
    ['news', 'zi-xun']
]);

const IntentionType = new Map([
    [
        'PRODUCT_COMPARISON',
        {
            name: '产品对比'
        }
    ],
    [
        'BATCH_INQUIRY',
        {
            name: '批量询价'
        }
    ]
]);

const collectType = new Map([
    ['PRODUCT', 'chan-pin'],
    ['VENDOR', 'chang-shang'],
    ['INFORMATION', 'wen-zhang']
]);

const productListType = new Map([
    ['OWN_PRODUCT', 'zi-shen-chan-pin-lei-xing-qing-dan'],
    ['REQUIRED_PRODUCT', 'xu-yao-de-chan-pin-qing-dan']
]);
const earnCycle = new Map([
    ['EVERY_DAY', 'mei-tian'],
    ['ONLY_ONE', 'yi-ci'],
    ['NO_LIMIT', 'wu-xian-zhi'],
    ['THREE_DAYS_IN_A_ROW', 'lian-xu-3-tian'],
    ['FIVE_DAYS_IN_A_ROW', 'lian-xu-5-tian'],
    ['TEN_DAYS_IN_A_ROW', 'lian-xu-10-tian']
]);

const userIdentity = new Map([
    ['BUYERS', '采购商'],
    ['VENDOR', '会员'],
    ['BOTH', '采购商,会员']
]);

const deviceStatus = new Map([
    ['PENDING_REVIEW', 'shen-he-zhong'],
    ['NOW_ON_SHELF', 'shang-jia-zhong'],
    ['HAS_BEEN_REMOVED', 'yi-xia-jia'],
    ['AUDIT_FAILURE', 'shen-he-shi-bai']
]);

const businessCategory = new Map([
    ['PRIVATE_COMPANY', 'min-ying'],
    ['STATE_OWNED_COMPANY', 'guo-qi'],
    ['FOREIGN_COMPANY', 'wai-zi'],
    ['JOINT_COMPANY', 'he-zi']
]);

const dayType = new Map([
    [1, 'jin-ri'],
    [2, 'zuo-tian'],
    [3, 'zong-ji']
]);
const datasType = new Map([
    [
        'uv',
        {
            name: 'zhan-ting-fang-ke-ren-shu',
            addKey: true
        }
    ],
    [
        'pv',
        {
            name: 'zhan-ting-liu-lan-liang',
            addKey: true
        }
    ],
    [
        'vv',
        {
            name: 'zhan-ting-fang-wen-ci-shu',
            addKey: true
        }
    ],
    [
        'advisory',
        {
            name: 'zi-xun-ren-shu'
        }
    ]
]);

const enterpriseSize = new Map([
    [
        '<20',
        {
            name: 'xiao-yu-20-ren'
        }
    ],
    [
        '21-50',
        {
            name: '20-dao-50-ren'
        }
    ],
    [
        '51-100',
        {
            name: '50-dao-100-ren'
        }
    ],
    [
        '101-200',
        {
            name: '100-dao-200-ren'
        }
    ],
    [
        '201-500',
        {
            name: '200-dao-500-ren'
        }
    ],
    [
        '501-1000',
        {
            name: '500-dao-1000-ren'
        }
    ],
    [
        '>1000',
        {
            name: '1000-yi-shang'
        }
    ]
]);

export {
    sexModels,
    languageModels,
    phonepre,
    InformationType,
    InformationTypeEdit,
    EnterpriseType,
    ProductSortType,
    BrandSortType,
    listType,
    searchType,
    boolModels,
    collectType,
    productListType,
    earnCycle,
    userIdentity,
    deviceStatus,
    businessCategory,
    dayType,
    datasType,
    enterpriseSize
};
