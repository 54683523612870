//意向单;
import { mapState, mapActions } from 'vuex';
export default {
    methods: {
        addIntentionList(productId) {
            this.checkLogin()
                .then(() => {
                    return this.$http.post('/intentionList/add', {
                        productId: productId,
                        type: 'BATCH_INQUIRY'
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('tian-jia-cheng-gong'));
                    this.$store.dispatch('getIntentionList');
                })
                .catch(e => {
                    console.log(e);
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        clearAllIntention() {
            this.$confirm(this.$t('que-ren-yao-qing-kong-quan-bu-chan-pin-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-qing-kong'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    return this.$http.post('/intentionList/delAll', {
                        type: 'BATCH_INQUIRY'
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('qing-kong-cheng-gong'));
                    this.$store.dispatch('getIntentionList');
                })
                .catch(() => {});
        },
        delIntention(id) {
            this.$confirm(this.$t('que-ren-yao-shan-chu-gai-shang-pin-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-shan-chu'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    return this.$http.post('/intentionList/delOne', {
                        type: 'BATCH_INQUIRY',
                        productId: id
                    });
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$store.dispatch('getIntentionList');
                })
                .catch(() => {});
        }
    },

    computed: {
        ...mapState(['intentionList'])
    }
};
