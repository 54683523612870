<template>
    <div class="message" @click="goDetail">
        <el-avatar :size="48" :src="img" icon="el-icon-user-solid"></el-avatar>
        <div class="main">
            <div class="top">
                <div class="name">{{ name }}</div>

                <div class="time" v-if="info">
                    {{ info.time }}
                </div>
            </div>

            <div class="content">
                <span> {{ content }}</span>

                <el-badge v-if="info && info.unreadNumber" :value="info.unreadNumber"> </el-badge>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'messageInfo',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['adminId']),
        img() {
            if (this.type === 'system') {
                return require('../../assets/liaotian_icon_xitong.png');
            } else if (this.info.otherUserId === this.adminId) {
                return require('../../assets/icon_fuwuguanjia.png');
            } else {
                return this.info.otherLogo || require('../../assets/img_defaultphoto.png');
            }
        },
        name() {
            if (this.type === 'system') {
                return this.$t('xi-tong-xiao-xi');
            } else if (this.info.otherUserId === this.adminId) {
                return this.$t('fu-wu-guan-jia');
            } else {
                return this.getName(this.info, ['otherName', 'otherEnName']) || this.$t('shen-mi-yong-hu');
            }
        },
        isClaim() {
            return this.contentJson && this.contentJson.companyName;
        },
        content() {
            if (this.type === 'system' && this.info) {
                return this.info.content || '';
            } else if (this.info && this.info.message) {
                if (this.info.message[0] === '{') {
                    if (this.info.otherUserId === this.adminId) {
                        return '...';
                    }
                    return this.isClaim
                        ? this.$t('ni-hao-wo-xiang-nin-fa-song-le-yi-tiao-qi-ye-ren-ling-xin-xi')
                        : this.$t(
                              'ni-hao-wo-dui-gui-fang-de-chan-pin-fei-chang-gan-xing-qu-xi-wang-ke-yi-xiang-xi-liao-liao'
                          );
                } else {
                    return this.info.message;
                }
            } else {
                return '';
            }
        },
        contentJson() {
            if (this.info.message) {
                if (this.info.message[0] === '{') {
                    if (this.info.message.split('=').length > 1) {
                        return null;
                    } else {
                        return JSON.parse(this.info.message);
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    },
    methods: {
        goDetail() {
            if (this.type === 'system') {
                this.$router.push('/chat');
            } else {
                this.$router.push('/chat?otherUserId=' + this.info.otherUserId);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.message {
    display: flex;
    overflow: hidden;
    padding: 12px 15px;
    cursor: pointer;
    &:hover {
        background-color: rgba(#f5f7fa, 0.6);
    }

    .main {
        flex-grow: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }
}

.top {
    display: flex;
    justify-content: space-between;

    .name {
        font-size: 12px;
        font-weight: bold;
        color: #292c33;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .time {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 22px;
        flex-shrink: 0;
        margin-left: 10px;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    span {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;

        font-size: 12px;
        color: #878d99;
        line-height: 22px;
    }
}
</style>
