<template>
    <div class="userInfo" :class="{ isDark: dark }">
        <div class="btnlist" v-if="userInfo">
            <el-popover placement="bottom" trigger="hover" width="300" v-model="showCompare" popper-class="userPop">
                <el-button slot="reference" type="text" class="btn1">
                    <el-badge
                        ref="compare"
                        :value="contrastList.length"
                        type="warning"
                        :hidden="contrastList.length === 0"
                    >
                        <span class="iconfont" :class="{ active: showCompare }">&#xe613;</span>
                    </el-badge>
                </el-button>

                <compared-order @getList="$store.dispatch('getContrast')"></compared-order>
            </el-popover>
            <el-popover
                placement="bottom"
                trigger="hover"
                v-model="showIntention"
                class="top-tips"
                popper-class="userPop"
                width="300"
            >
                <el-button slot="reference" type="text" class="btn1">
                    <el-badge ref="intention" type="warning">
                        <span class="iconfont" :class="{ active: showIntention }">&#xe612;</span>
                    </el-badge>
                </el-button>

                <intention-order @getList="$store.dispatch('getIntentionList')"></intention-order>
            </el-popover>
            <el-popover
                placement="bottom-end"
                class="top-tips"
                trigger="hover"
                width="500"
                popper-class="userPop"
                @show="emailShow"
            >
                <el-button slot="reference" type="text" class="btn1">
                    <el-badge is-dot type="warning" :hidden="!hasNoRead">
                        <span class="iconfont" :class="{ active: showEmail }">&#xe614;</span>
                    </el-badge>
                </el-button>
                <email-order ref="emailOrder"></email-order>
            </el-popover>
            <el-dropdown placement="bottom" @command="doUserOperate" style="margin-left: 20px">
                <el-button type="text" class="btn1">
                    <el-avatar :size="32" :src="userIcon"></el-avatar>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="home">{{ nickname }}</el-dropdown-item>
                    <el-dropdown-item v-if="isVendor" divided command="product">{{
                        $t('chan-pin-guan-li')
                    }}</el-dropdown-item>

                    <el-dropdown-item command="collection" :divided="!isVendor">{{
                        $t('wo-de-shou-cang')
                    }}</el-dropdown-item>
                    <!-- <el-dropdown-item command="minePoint"
                        >我的积分</el-dropdown-item
                    > -->

                    <el-dropdown-item command="readRecords">{{ $t('liu-lan-ji-lu') }}</el-dropdown-item>
                    <el-dropdown-item divided command="mineVendorInfo">{{ $t('zi-liao-bian-ji') }}</el-dropdown-item>
                    <el-dropdown-item command="user">{{ $t('zhang-hu-yu-an-quan') }}</el-dropdown-item>
                    <el-dropdown-item divided command="logout">{{ $t('tui-chu-deng-lu') }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <div class="btnlist" v-else>
            <el-button type="text" class="btn1" @click="goLogin">[{{ $t('deng-lu') }}]</el-button>
            <el-button type="text" class="btn2" @click="goRegister">[{{ $t('zhu-ce') }}]</el-button>
        </div>

        <div class="laguage-group">
            <div class="language-item" @click="changeLocales('zh')" :class="{ active: isChinese }">中文</div>
            <div class="language-item" @click="changeLocales('en')" :class="{ active: !isChinese }">English</div>
        </div>

        <!-- <el-dropdown
            class="language"
            placement="bottom"
            @command="changeLocales"
            szie="mini"
        >
            <span class="el-dropdown-link">
                {{ isChinese ? '中文' : 'English' }}<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="en">English </el-dropdown-item>
                <el-dropdown-item command="zh"> 中文 </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown> -->
    </div>
</template>
<script>
import { mapState } from 'vuex';
import UserState from '../../mixins/UserState';
import { animateCSS } from '../../utils/Animate';
import ComparedOrder from '../compared/Order.vue';
import IntentionOrder from '../compared/IntentionOrder.vue';
import EmailOrder from '../email/Order';
export default {
    name: 'HeaderUser',
    mixins: [UserState],
    props: {
        dark: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultStyle: {},
            showCompare: false,
            showIntention: false,
            showEmail: false
        };
    },
    watch: {
        'contrastList.length'() {
            if (this.contrastList.length > 0) {
                this.$nextTick(() => {
                    this.moveAnimate(this.$refs.compare.$el);
                });
            }
        }
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo', 'emailInfo', 'messageList']),
        isChinese() {
            return this.$i18n.locale === 'zh';
        },
        userIcon() {
            return this.buyersInfo && this.userInfo && this.userInfo.avatar
                ? this.buyersInfo.logo
                    ? this.buyersInfo.logo + '?x-oss-process=image/resize,m_fill,h_240,w_240'
                    : this.userInfo.avatar
                : require('../../assets/img_defaultphoto.png');
        },
        nickname() {
            if (this.userInfo) {
                if (this.userInfo.parentId && this.buyersInfo) {
                    return (
                        this.getName(this.buyersInfo, ['chCompanyName', 'enCompanyName']) +
                        `(${this.userInfo.username})`
                    );
                } else if (!this.userInfo.chCompanyName && this.userInfo.phone) {
                    return '用户' + this.userInfo.phone.substr(0, 3) + '****' + this.userInfo.phone.substr(-4, 4);
                } else {
                    return this.getName(this.userInfo, ['chCompanyName', 'enCompanyName']);
                }
            }

            return '';
        },
        hasNoRead() {
            if (
                (this.emailInfo && !!this.emailInfo.unreadNumber) ||
                !![...this.messageList].find(item => {
                    return !!item.unreadNumber;
                })
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        changeLocales(lang) {
            this.$i18n.locale = lang;
            window.sessionStorage.setItem('locale', lang);
            this.$emit('scrollEvent');
        },
        doUserOperate(operate) {
            console.log(operate);
            if (operate === 'logout') {
                this.logout();
            } else if (operate === 'home') {
                this.$router.push(this.isVendor ? '/vendorDatas' : '/mineVendorInfo');
            } else if (operate === 'user') {
                this.$router.push('/accountInfo');
            } else if (operate === 'collection') {
                this.$router.push('/mineCollection');
            } else if (operate === 'readRecords') {
                this.$router.push('/mineReadRecords');
            } else if (operate === 'minePoint') {
                this.$router.push('/minePoint');
            } else if (operate === 'mineVendorInfo') {
                this.$router.push('/mineVendorInfo');
            } else if (operate === 'product') {
                this.$router.push('/vendor/product');
            }
        },
        moveAnimate(parent) {
            this.$nextTick(() => {
                let nodes = parent.childNodes;
                let _node = [...nodes].find(item => {
                    return item.nodeName === 'SUP';
                });
                animateCSS(_node, 'bounceInUp', '', 0.8);
            });
        },
        emailShow() {
            this.$refs.emailOrder.init();
        }
    },
    components: {
        ComparedOrder,
        IntentionOrder,
        EmailOrder
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.userInfo {
    display: flex;
    align-items: center;
}
.tip {
    flex-grow: 1;
    min-width: 10px;
}
.btn1 {
    color: #0f264d;
    &:hover {
        color: $--color-text-primary;
    }
    padding: 0px 0px;
    display: block;
}

.btn2 {
    color: $--color-text-primary;

    &:hover {
        color: mix($--color-black, $--color-text-primary, $--button-active-shade-percent);
    }
}

.btn3 {
    border-width: 0px;
}

.iconfont {
    font-size: 32px;

    &.active {
        color: $--color-text-primary;
    }
}

.el-button--warning.is-plain {
    background-color: rgba(255, 173, 51, 0.12);
    margin-right: 10px;
    padding: 10px 22px;
}
.language {
    min-width: 60px;
}
.el-dropdown-link {
    color: #565b66;
    font-size: 12px;
    line-height: 17px;
    cursor: pointer;
}
.top-tips {
    margin-left: 0.14rem;
}
.btnlist {
    margin-right: 0.36rem;
    margin-left: 0.3rem;
    .el-button + .el-button {
        margin-left: 20px;
    }
    display: flex;
    align-items: center;
}

.isDark {
    .btn1 {
        color: $--menu-item-font-color;
        &:hover {
            color: $--color-text-primary;
        }
    }

    .el-dropdown-link {
        color: #fff;
    }
}

.user-icon {
    width: 30px;
    height: 30px;
}
</style>

<style lang="scss">
.el-button {
    .el-badge__content.is-fixed {
        top: -5px;
        right: -8px;
        height: 16px;
        line-height: 16px;
        border-width: 0;
        transform: none;
    }
    .el-badge__content.is-fixed.is-dot {
        top: 0px;
        right: 0px;
        width: 6px;
        height: 6px;
        border-width: 0;
    }
}
.el-popover.userPop {
    border-radius: 0px;
    border-width: 0px;
    padding: 0;
}

.laguage-group {
    display: flex;
    font-size: 12px;
    color: #0f264d;
    line-height: 17px;
    flex-shrink: 0;

    .language-item {
        cursor: pointer;
        position: relative;
        color: #bcc1cc;
        padding: 6px;

        &:not(:first-child) {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                background-color: #bcc1cc;
                width: 1px;
                height: 6px;
                top: 12px;
            }
        }

        &:hover {
            color: #0f264d88;
        }

        &.active {
            color: #0f264d;
            font-weight: bold;
        }
    }
}

.isDark {
    .laguage-group {
        color: #ffffff;
    }
    .language-item {
        color: #f5f7fa66;

        &:hover {
            color: #f5f7fa;
        }

        &.active {
            color: #fff;
            font-weight: normal;
        }
    }
}
</style>
