import http from '../plugins/http';
import { Message } from 'element-ui';

function sendMsg(phone, isEn = false) {
    let _phone = phone.replace(/\+86/g, '');
    return http.axios
        .get(isEn ? '/sms/sendVerifyEn' : '/sms/sendVerify', {
            phone: _phone
        })
        .then(() => {
            Message.success('发送成功');
            return Promise.resolve();
        })
        .catch(e => {
            Message.error(e.error);
            return Promise.reject();
        });
}

function verifySms(phone, code) {
    let _phone = phone.replace(/\+86/g, '');
    return http.axios
        .get('/sms/verify', {
            phone: _phone,
            code: code
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(e => {
            Message.error(e.error);
            return Promise.reject();
        });
}

const regular = /^\+861[3456789]\d{9}$/;

function countDown(num = 60) {
    if (num == 0) {
        return null;
    } else {
        return num - 1;
    }
}

function formatPhone(phone) {
    if (regular.test('+86' + phone)) {
        return phone.substr(0, 3) + '****' + phone.substr(-4, 4);
    }
    return '';
}

function backPhone(phone) {
    let _phone = phone.replace(/\+86/g, '');
    return _phone;
}

export { sendMsg, regular, countDown, formatPhone, backPhone, verifySms };
