<template>
    <div>
        <div :id="'upload-wrapper-' + name" class="upload-wrapper" :style="uploadStyle">
            <img v-if="src" :src="src" oncontextmenu="return false;" />
            <div v-else class="upload-default">
                {{ $t('dian-ji-shang-chuan') }}
            </div>
            <div v-if="loading" class="loading">
                <i class="el-icon-loading"></i>
            </div>
        </div>
        <avatar-cropper
            ref="cropper"
            @submit="loading = true"
            @uploaded="handleUploaded"
            :trigger="'#upload-wrapper-' + name"
            :cropper-options="cropperOptions"
            :output-options="outputOptions"
            :output-quality="1"
            :upload-url="uploadUrl"
            :labels="{ submit: $t('que-ding'), cancel: $t('qu-xiao') }"
            :upload-headers="headers"
        />

        <div class="tips">
            <slot name="tips"></slot>
        </div>
    </div>
</template>
<script>
import resolveUrl from 'resolve-url';
import AvatarCropper from 'vue-avatar-cropper';

export default {
    props: {
        value: {},
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        ratio: {
            type: Array,
            default: [1, 1]
        },
        imgWidth: {
            type: Number,
            default: 100
        },
        name: {
            type: String,
            default: ''
        }
    },
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        if (this.value) {
            this.src = this.value;
        }
    },
    mounted() {
        document.body.appendChild(this.$refs.cropper.$el);
    },
    beforeDestroy() {
        document.body.removeChild(this.$refs.cropper.$el);
    },
    data() {
        return {
            uploadUrl: '',
            src: '',
            loading: false,
            headers: {
                // Authorization: 'Bearer ' + localStorage.getItem('token'),
            }
        };
    },
    computed: {
        outputOptions() {
            return { width: this.width * 2, height: this.height * 2 };
        },
        cropperOptions() {
            return {
                aspectRatio: Number((this.ratio[0] / this.ratio[1]).toFixed(2)),
                viewMode: 2,
                guides: false,
                modal: false,
                autoCropArea: 1
            };
        },
        uploadStyle() {
            return {
                width: this.imgWidth + 'px',
                height: (this.imgWidth * this.ratio[1]) / this.ratio[0] + 'px'
            };
        }
    },
    watch: {
        value() {
            if (this.value !== this.src) {
                this.src = this.value;
            }
        }
    },
    methods: {
        handleUploaded(res) {
            this.loading = false;
            this.src = res;
            this.$emit('input', res);
        }
    },
    components: {
        AvatarCropper
    }
};
</script>
<style lang="scss" scoped>
.upload-wrapper {
    width: 178px;
    height: 178px;
    display: block;
    border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }

    &:hover {
        border-color: #ffa526;

        .upload-default {
            color: #ffa526;
        }
    }
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fbfdff;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    color: #333;
    font-size: 24px;
}

.upload-default {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f7fa;
    color: #bcc1cc;
    font-size: 12px;
}

.tips {
    font-size: 12px;
    font-weight: 400;
    color: #bcc1cc;
    line-height: 17px;
    margin-top: 12px;
}
</style>
