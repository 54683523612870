<template>
    <div class="Robot">
        <div class="robotBox">
            <el-popover v-model="showHover" placement="right-end" trigger="click" popper-class="right-popover">
                <img slot="reference" ref="robotImg" class="img" src="../../assets/icon_fuwuguanjia.png" alt="" />

                <div class="hoverContent">
                    <div class="content-text">
                        {{ $t('dui-jie-wo-men-de-fu-wu-guan-jia-wo-men-hui-zhen-dui-nin-de-ju-ti-xu-qiu') }}
                        <br v-if="isChinese" />
                        {{
                            $t(
                                'yi-kao-wo-men-de-pang-da-de-zi-yuan-ji-qun-wei-nin-ti-gong-zhuan-ye-de-ding-zhi-hua-yi-zhan-shi-fu-wu'
                            )
                        }}
                    </div>
                    <div class="content-labels">
                        <el-button
                            v-for="(item, index) in services"
                            :key="index"
                            size="medium"
                            type="warning"
                            plain
                            @click="serviceClick(item)"
                            >{{ item }}</el-button
                        >
                    </div>
                </div>
            </el-popover>
            <div class="tips" ref="tips" v-if="showTips">
                {{ $t('wei-nin-ti-gong-zhi-neng-zhi-zao-chan-ye-ding-zhi-hua-gong-neng-fu-wu') }}
            </div>
        </div>

        <el-dialog class="robot-dialog" :visible.sync="showDialog" width="600px" append-to-body center>
            <div class="dialog-top bg" slot="title">
                {{ $t('zi-xun-fu-wu-guan-jia') }}
            </div>
            <el-form :model="form" ref="form" label-width="175px">
                <!-- <el-form-item label="姓名" prop="nickname">
                    <el-input readonly v-model="form.nickname" class="input2" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input readonly v-model="form.phone" placeholder="请输入您的联系方式" class="input1"> </el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input readonly class="input1" v-model="form.email" placeholder="请输入您的邮箱"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="chCompanyName">
                    <el-input
                        readonly
                        class="input1"
                        v-model="form.chCompanyName"
                        placeholder="请输入您的公司名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="职位" prop="position">
                    <el-input readonly class="input1" v-model="form.position" placeholder="请输入您的职位"></el-input>
                </el-form-item> -->
                <el-form-item :label="$t('gong-neng-xu-qiu')">
                    <el-select
                        class="input2"
                        v-model="form.service"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        :placeholder="$t('qing-xuan-ze-gong-neng-xu-qiu')"
                    >
                        <el-option v-for="(item, index) in services" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('ju-ti-xu-qiu')">
                    <el-input
                        class="input2"
                        type="textarea"
                        v-model="form.message"
                        :autosize="{ minRows: 5, maxRows: 6 }"
                        :maxlength="100"
                        :placeholder="$t('qing-bu-chong-miao-shu-nin-de-xu-qiu-nei-rong-100-zi-yi-nei')"
                        show-word-limit
                        resize="none"
                    ></el-input>
                </el-form-item>

                <el-form-item lable-width="0px">
                    <el-button type="warning" @click="sbumit">{{ $t('ti-jiao') }}</el-button>
                    <el-button @click="showDialog = false">{{ $t('qu-xiao') }}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { animateCSS } from '../../utils/Animate';
import { sexModels } from '../../utils/AppState';
export default {
    name: 'Robot',
    data() {
        return {
            showTips: false,
            showHover: false,
            services: [],
            showDialog: false,
            form: {
                service: []
            }
        };
    },
    watch: {
        isChinese() {
            this.getServices();
        }
    },
    mounted() {
        this.getServices();
        setTimeout(() => {
            this.init();
            document.body.appendChild(this.$el);
        }, 1000);
    },
    computed: {
        ...mapState(['userInfo']),
        sexModels() {
            return [...sexModels].map(item => {
                return [item[0], { label: this.$t(item[1].label) }];
            });
        }
    },
    beforeDestroy() {
        document.body.removeChild(this.$el);
    },
    methods: {
        getServices() {
            this.$http
                .get(`/sysConfig/get/DEMAND_SERVCE` + (this.isChinese ? '' : '_EN'))
                .then(res => {
                    if (res.value) {
                        return Promise.resolve(res);
                    } else {
                        return this.$http.get(`/sysConfig/get/DEMAND_SERVCE`);
                    }
                })
                .then(res => {
                    this.services = res.value.split(',');
                });
        },
        init() {
            this.$nextTick(() => {
                animateCSS(this.$refs.robotImg, 'backInRight', '', 1.2)
                    .then(() => {
                        this.showTips = true;
                        return this.$nextTick(() => {
                            return animateCSS(this.$refs.tips, 'backInRight');
                        });
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.heartBeat();
                            animateCSS(this.$refs.tips, 'backOutRight').then(() => {
                                this.showTips = false;
                            });
                        }, 3000);
                    })
                    .catch(e => {});
            });
        },
        robotHover() {
            // if (this.showTips || this.showHover) return;
            // animateCSS(this.$refs.robotImg, 'rubberBand', '', 0.6).then(() => {
            //     this.showHover = true;
            // });
        },
        robotLeave() {
            this.showHover = false;
        },
        heartBeat() {
            if (!this.showHover && !this.showTips && !this.showDialog) {
                animateCSS(this.$refs.robotImg, 'headShake', '', 0.8);
                setTimeout(() => {
                    this.heartBeat();
                }, 20000);
            }
        },
        serviceClick(item) {
            this.checkLogin()
                .then(() => {
                    this.form = {
                        ...this.userInfo,
                        service: [item]
                    };
                    this.showDialog = true;
                })
                .catch(e => {
                    console.log(e);
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        sbumit() {
            this.$http
                .post(
                    '/demandSteward/save',
                    {
                        functionalRequirements: this.form.service.join(','),
                        otherProblems: this.form.message,
                        userId: this.userInfo.id
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.$message.success(this.$t('ti-jiao-cheng-gong'));
                    return this.$http.post('/user/getAdmin');
                })
                .then(res => {
                    this.showDialog = false;
                    this.$router.push('/chat?otherUserId=' + res);
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.robotBox {
    position: fixed;
    right: 0px;
    bottom: 50px;
    z-index: 300;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    overflow: hidden;
    padding: 20px;

    .img {
        width: 58px;
        height: 58px;
        display: block;
        position: relative;
        z-index: 2;
        cursor: pointer;
        border-width: 0;
        transition: transform ease-in-out 0.3s;
        transform: translate3d(0, 0, 0);
        &:hover {
            transform: scale(1.2);
        }
        &.imgActive {
            transform: scale(1.2);
        }
    }

    .tips {
        align-self: center;
        height: 36px;
        background: mix($--color-black, rgba(20, 86, 199, 0.48), $--button-active-shade-percent);
        border: 1px solid #75b3fb;
        padding: 0 41px 0 15px;
        white-space: nowrap;
        z-index: 1;
        font-size: 12px;
        color: #deefff;
        line-height: 36px;
        transform: translateX(28px);
    }
}

.hoverContent {
    background-color: #ffffff;
    padding: 30px 26px 30px 16px;
    width: 460px;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px 0px 8px 8px;
    // margin-bottom: 20px;
    // margin-top: 29px;
    // margin-right: 10px;

    .content-text {
        font-size: 12px;
        color: #565b66;
        line-height: 22px;
        text-align: center;
        word-wrap: break-word;
    }

    .el-button--medium {
        font-size: 12px;
        border-width: 0px;
        line-height: 14px;
        margin-top: 10px;
    }

    .content-labels {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 12px;
    }
}

.dialog-top {
    background-image: url(http://oss.imttech.cn/case/fuwuguanjia_img2x.jpg);
    height: 120px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 120px;
    text-align: center;
}
.el-button + .el-button {
    margin-left: 20px;
}

.robot-dialog {
    .el-button {
        font-size: 12px;
        line-height: 14px;
        padding: 12px 10px;
        &:first-child {
            width: 160px;
        }

        &:last-child {
            width: 120px;
        }
    }
}
</style>
<style lang="scss">
.robot-dialog {
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__headerbtn {
        font-size: 20px;
        .el-dialog__close {
            color: #fff;
        }
    }

    .el-form-item__label {
        padding-right: 20px;
    }
}

.el-form {
    margin: auto;
    max-width: 550px;
}

.right-popover.el-popover {
    padding: 0;
    box-shadow: none;
    border-width: 0;
}
</style>
