<template>
    <el-select
        v-model="value1"
        :disabled="disabled"
        multiple
        :placeholder="$t('qing-xuan-ze')"
        style="width: 100%"
        @change="changeSelect"
        filterable
    >
        <el-option v-for="item in products" :key="item.id" :label="getName(item)" :value="item.id"> </el-option>
    </el-select>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'ProductSelect',
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isOwn: {
            type: Boolean,
            default: true
        },
        vendorId: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        chooseList: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            products: [],
            value1: []
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        vendorInfoId() {
            return this.isOwn ? this.buyersInfo.vendorId : this.vendorId;
        }
    },
    mounted() {
        if (this.value.length !== 0) {
            this.value1 = this.value;
        }
        if (this.isOwn) {
            this.getInfo();
        } else {
            if (this.vendorInfoId) {
                this.getInfo();
            }
        }
    },
    watch: {
        value() {
            console.log(this.value);
            if (this.value.length !== 0 && this.value1.length === 0) {
                this.value1 = this.value;
            } else if (this.value.length === 0 && this.value1.length !== 0) {
                this.value1 = this.value;
            }
        },
        vendorInfoId() {
            if (this.vendorInfoId) {
                this.getInfo();
            }
        }
    },
    methods: {
        setValue() {
            this.value1 = this.value;
        },
        changeSelect() {
            console.log(this.value1);
            this.$nextTick(() => {
                this.$emit('input', this.value1);
            });
        },
        getInfo() {
            if (!this.vendorInfoId) {
                this.products = this.chooseList;
                return;
            }
            this.$http
                .get('/product/my', {
                    vendorId: this.vendorInfoId,
                    status: this.isOwn ? 0 : 1
                })
                .then(res => {
                    this.products = res;
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
