const animateCSS = (node, animation, prefix = '', duration = 0.3) =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
        node.style.setProperty('animation-duration', `${duration}s`);
        const animationName = `${prefix}${animation}`;
        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd() {
            if (node) {
                node.classList.remove(`${prefix}animated`, animationName);
                node.removeEventListener('animationend', handleAnimationEnd);

                resolve('Animation ended');
            } else {
                reject();
            }
        }

        node.addEventListener('animationend', handleAnimationEnd);
    });

export { animateCSS };
