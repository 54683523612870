<template>
    <tinymce-editor v-if="show" :init="init" :value="value" @input="onInput"> </tinymce-editor>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce/tinymce';
import './zh_CN';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import '../utils/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/imagetools';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/icons/default/index';
import { Loading } from 'element-ui';
export default {
    props: ['value', 'disabled'],
    data() {
        return {
            init: {
                language: 'zh_CN',
                height: 500,
                min_height: 350,
                menubar: false,
                autoresize_on_init: false,
                statusbar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor autoresize',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media mediaembed table paste code help wordcount'
                ],
                toolbar:
                    'undo redo | image media | fontsizeselect | forecolor backcolor bold italic underline strikethrough | \
                    alignleft aligncenter alignright alignjustify | bullist numlist  lineheight fullscreen',
                fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                file_picker_types: 'media image',
                file_picker_callback: (cb, value, meta) => {
                    if (meta.filetype == 'media') {
                        //创建一个隐藏的type=file的文件选择input

                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'video/*');
                        const _this = this;
                        input.onchange = function () {
                            let loadingInstance = Loading.service({ lock: true });
                            let file = this.files[0]; //只选取第一个文件。如果要选取全部，后面注意做修改
                            let formData = new FormData();
                            console.log(file);
                            console.log(value);
                            formData.append('file', file);
                            _this.$axios
                                .post('/upload/file', formData)
                                .then(res => {
                                    loadingInstance.close();
                                    cb(res.data, {
                                        title: file.name
                                    });
                                })
                                .catch(e => {
                                    loadingInstance.close();
                                    failure(e);
                                });
                        };
                        //触发点击
                        input.click();
                    } else if (meta.filetype == 'image') {
                        //创建一个隐藏的type=file的文件选择input

                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        const _this = this;
                        input.onchange = function () {
                            let loadingInstance = Loading.service({ lock: true });
                            let file = this.files[0]; //只选取第一个文件。如果要选取全部，后面注意做修改
                            let formData = new FormData();
                            formData.append('file', file);
                            _this.$axios
                                .post('/upload/file', formData)
                                .then(res => {
                                    loadingInstance.close();
                                    cb(res.data, { title: file.name });
                                })
                                .catch(e => {
                                    loadingInstance.close();
                                    failure(e);
                                });
                        };
                        //触发点击
                        input.click();
                    }
                },
                font_formats:
                    '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
                images_upload_url: this.$baseUrl + '/upload/file',
                images_upload_handler: (blobInfo, success, failure) => {
                    let formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    this.$axios
                        .post('/upload/file', formData)
                        .then(res => {
                            success(res.data);
                        })
                        .catch(e => {
                            failure(e);
                        });
                },
                init_instance_callback: editor => {},
                image_class_list: [
                    { title: '自适应屏幕图片', value: 'blockImg' },
                    { title: '自定义图片', value: 'none' }
                ],
                content_style:
                    '.blockImg {width:100%;height:auto;display:block} video{width:100%;height:auto;display:block} p {display: block;margin-block-start:0;margin-block-end: 0;margin-inline-start: 0px; margin-inline-end: 0px;}',
                autosave_ask_before_unload: false,
                toolbar_mode: 'floating',
                video_template_callback: function (data) {
                    return (
                        '<video width="100%" height="auto" style="display:block;"' +
                        (data.poster ? ' poster="' + data.poster + '"' : '') +
                        ' controls="controls">\n' +
                        '<source src="' +
                        data.source +
                        '"' +
                        (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') +
                        ' />\n' +
                        (data.altsource
                            ? '<source src="' +
                              data.altsource +
                              '"' +
                              (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') +
                              ' />\n'
                            : '') +
                        '</video>'
                    );
                }
            },
            editor: null,
            content: '',
            show: false
        };
    },
    watch: {
        isChinese() {
            this.changeLanguage();
        }
    },
    mounted() {
        this.changeLanguage();
    },

    methods: {
        onInput(val) {
            this.$emit('input', val);
        },
        changeLanguage() {
            this.show = false;
            if (this.isChinese) {
                this.init.language = 'zh_CN';
            } else {
                this.init.language = 'en';
            }
            this.$nextTick(() => {
                this.show = true;
            });
        },
        initEven(e) {
            console.log(e);
        }
    },
    components: {
        'tinymce-editor': Editor // <- Important part
    }
};
</script>
<style lang="scss" scoped>
.preview {
    overflow: auto;
    border: 1px solid #ebebeb;
    background-color: #fbfdff;
    border-radius: 4px;
}
</style>
<style lang="scss">
.tox .tox-toolbar__primary {
    background-color: #f5f7fa;
}

.ephox-summary-card {
    border: 1px solid #aaa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 10px;
    overflow: hidden;
    margin-bottom: 1em;
}

.ephox-summary-card a {
    text-decoration: none;
    color: inherit;
}

.ephox-summary-card a:visited {
    color: inherit;
}

.ephox-summary-card-title {
    font-size: 1.2em;
    display: block;
}

.ephox-summary-card-author {
    color: #999;
    display: block;
    margin-top: 0.5em;
}

.ephox-summary-card-website {
    color: #999;
    display: block;
    margin-top: 0.5em;
}

.ephox-summary-card-thumbnail {
    max-width: 180px;
    max-height: 180px;
    margin-left: 2em;
    float: right;
}

.ephox-summary-card-description {
    margin-top: 0.5em;
    display: block;
}
</style>
<style lang="scss">
.tox .tox-form__grid--2col > .tox-form__group {
    width: 100%;
}

.tox-form {
    .tox-form__group {
        margin-bottom: 20px;
        .tox-dropzone-container {
            width: 100%;
        }
        display: flex;
        align-items: center;
        .tox-form__controls-h-stack {
            flex-grow: 1;
            .tox-form__group {
                display: none;
            }
        }

        .tox-listboxfield {
            flex-grow: 1;
        }

        &.tox-form__group--stretched {
            label {
                margin-bottom: 10px;
            }
        }
    }
}
.tox .tox-dialog__footer {
    border-top: 1px solid #dcdfe6;
}
.tox .tox-form__group--stretched .tox-textarea {
    min-height: 200px;
}
.tox .tox-dialog__body-content {
    min-height: 200px;
    border-left: 1px solid #dcdfe6;
}
.tox .tox-dialog__header {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdfe6;
}
.tox .tox-button {
    background-color: #ffa526;
    border-color: #ffa526;
    &:active {
        background-color: #ffa526;
    }

    &.tox-button--secondary {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
    }
    &.tox-button--naked {
        background-color: transparent;
        border-color: transparent;
    }
}
.tox .tox-dialog__body-nav-item {
    margin-bottom: 20px;
    &:hover {
        color: #ffa526;
    }
    &:focus {
        background-color: transparent;
    }
}
.tox .tox-dialog__body-nav-item--active {
    border-bottom: 2px solid #ffa526;
    color: #ffa526;
}
</style>
