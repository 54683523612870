import http from '../plugins/http';
import store from '../store';

function getBanner(key, isEN = false) {
    return http.axios
        .post(
            '/banner/all',
            {
                query: {
                    position: key,
                    isMa: false,
                    del: false
                }
            },
            { body: 'json' }
        )
        .then(res => {
            const list=res.content.filter(item=>{
                return item.en===isEN
            })
            return Promise.resolve(list.length>0?list:res.content);
        });
}

function saveBrowse(cid, type = 'PRODUCT') {
    if (!cid) {
        return Promise.reject();
    }
    return http.axios
        .post('/collect/saveBrowse', {
            type: type,
            cid: cid
        })
        .then(res => {
            return Promise.resolve(res);
        });
}

// [
// 	// 产商
//     'VENDOR',
//     /*
//     产品
//      */
//     'PRODUCT',
//     /*
//     文章
//      */
//     'INFORMATION'
// ];
function saveStatistics(cid, type = 'PRODUCT', isLeave = false) {
    // console.log(cid, isLeave);
    if (!cid || !store.state.userInfo) {
        return Promise.reject();
    }
    // return Promise.reject();
    return http.axios
        .post(
            '/statistics/save',
            {
                type: type,
                cid: cid,
                userId: store.state.userInfo.id,
                isLeave: isLeave
            },
            { body: 'json' }
        )
        .then(res => {
            return Promise.resolve(res);
        });
}

// 模糊匹配
function matchingName(search = '', typeName = 'product') {
    if (search) {
        const getMatch = (str, type) => {
            if (type === 'product') {
                return http.axios.post(
                    '/product/all',
                    {
                        search: str,
                        page: 0,
                        size: 10,
                        query: {
                            deviceStatus: 'NOW_ON_SHELF',
                            del: false
                        }
                    },
                    { body: 'json' }
                );
            } else if (type === 'brand') {
                return http.axios.post(
                    '/vendorInfo/all',
                    {
                        search: str,
                        page: 0,
                        size: 10,
                        query: {
                            status: 'PASS',
                            del: false
                        }
                    },
                    { body: 'json' }
                );
            } else {
                return http.axios.post(
                    '/information/all',
                    {
                        search: str,
                        page: 0,
                        size: 10,
                        query: {
                            status: 'PASS',
                            del: false
                        }
                    },
                    { body: 'json' }
                );
            }
        };

        return getMatch(search, typeName).then(res => {
            return Promise.resolve(
                res.content.map(item => {
                    if (typeName === 'brand') {
                        return {
                            chName: item.chCompanyName,
                            enName: item.enCompanyName
                        };
                    } else if (typeName === 'news') {
                        return {
                            chName: item.title,
                            enName: item.title
                        };
                    } else {
                        return {
                            chName: item.chName,
                            enName: item.enName
                        };
                    }
                })
            );
        });
    }
}

export { getBanner, saveBrowse, saveStatistics, matchingName };
