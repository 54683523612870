<template>
    <div class="product mini">
        <el-avatar shape="square" :size="60" :src="img"></el-avatar>
        <div class="name" @click="$router.push('/productDetail?id=' + info.id)">
            {{ getName(info) }}
        </div>
        <el-button v-if="hasDel" type="text" icon="el-icon-delete" @click="del"></el-button>
    </div>
</template>
<script>
export default {
    name: 'product',
    data() {
        return {};
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        hasDel: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        img() {
            if (this.info.img) {
                return this.info.img.split(',')[0];
            } else {
                return '';
            }
        }
    },
    methods: {
        del() {
            this.$emit('delete', this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.product {
    display: flex;
    padding: 8px 0;
    overflow: hidden;

    .name {
        font-size: 12px;
        font-weight: 400;
        color: #292c33;
        line-height: 22px;
        padding: 0 10px;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .el-button {
        align-self: center;
        color: #c6cad4;
        &:hover {
            color: #ffa526;
        }
    }
}
</style>

<style lang="scss">
.product.mini {
    .el-link {
        font-size: 12px;
        line-height: 22px;
    }
}
</style>
