<template>
    <div :style="defaultStyle" class="defaaultHeader">
        <el-header
            height="90px"
            id="header"
            class="header"
            :style="{ left: 0 - left + 'px', background: `rgba(15,38,77,${opacity || defaultOpacity}` }"
        >
            <div class="header-main">
                <img class="logo" @click="$router.push('/')" src="../../assets/nav_logo.png" alt="" />
                <el-menu :default-active="activeIndex" class="menu" mode="horizontal" router>
                    <el-menu-item index="/home">
                        <router-link to="/home">{{ $t('shou-ye') }}</router-link>
                    </el-menu-item>
                    <el-menu-item index="/IMT">
                        <router-link to="/IMT">{{ $t('imt-ping-tai') }}</router-link></el-menu-item
                    >
                    <el-menu-item index="/brand">
                        <router-link to="/brand">{{ $t('zhan-shang-zong-lan') }}</router-link></el-menu-item
                    >
                    <el-menu-item index="/product">
                        <router-link to="/product">{{ $t('quan-qiu-chan-pin') }}</router-link></el-menu-item
                    >
                    <!-- <el-menu-item index="2">成为会员</el-menu-item> -->

                    <el-menu-item index="/NEWS">
                        <router-link to="/NEWS">{{ $t('xin-xi-zi-xun') }} </router-link></el-menu-item
                    >
                </el-menu>
                <div class="tip"></div>

                <el-button
                    v-if="userInfo && (!buyersInfo || !buyersInfo.status)"
                    class="btn3"
                    type="warning"
                    plain
                    size="medium"
                    @click="$router.push('/manufacturersEntering')"
                    >{{ $t('hui-yuan-ru-zhu') }}</el-button
                >

                <template v-if="buyersInfo">
                    <el-button
                        v-if="buyersInfo.status === 'PENDING'"
                        class="btn3"
                        type="warning"
                        size="medium"
                        @click="$router.push('/enterRule3')"
                        >{{ $t('ru-zhu-shen-he-zhong') }}</el-button
                    >

                    <el-button
                        v-if="buyersInfo.status === 'DENY'"
                        class="btn3"
                        type="danger"
                        plain
                        size="medium"
                        @click="$router.push('/enterRule2')"
                        >{{ $t('ru-zhu-shen-he-shi-bai') }}</el-button
                    >
                </template>

                <SearchInput v-if="showSearch" />

                <HeaderUser dark @scrollEvent="scrollEvent" />
            </div>
        </el-header>
    </div>
</template>
<script>
import SearchInput from '../SearchInput';
import HeaderUser from './HeaderUser';
import { mapState } from 'vuex';
export default {
    name: 'Header',
    data() {
        return {
            languaesMap: new Map([
                [
                    'en',
                    {
                        name: 'EN',
                        icon: require('../../assets/nav_icon_guoqi_en.png')
                    }
                ],
                [
                    'zh',
                    {
                        name: 'CH',
                        icon: require('../../assets/nav_icon_guoqi_ch.png')
                    }
                ]
            ]),
            defaultStyle: {},
            left: 0,
            opacity: 0
        };
    },
    watch: {
        $route() {
            this.$nextTick(() => {
                if (document.getElementById('banner')) {
                    this.defaultStyle = {};
                } else {
                    this.defaultStyle = {
                        height: '90px',
                        backgroundColor: '#0F264D'
                    };
                }
            });
        }
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo']),
        activeIndex() {
            return this.$route.path;
        },
        showSearch() {
            if (this.$route.path !== '/home' && this.$route.path !== '/search') {
                return true;
            } else {
                return false;
            }
        },
        defaultOpacity() {
            if (['home', 'brand', 'product'].indexOf(this.$route.name) !== -1) {
                return 0.3;
            } else {
                return 0;
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollEvent);
        this.$nextTick(() => {
            if (document.getElementById('banner')) {
                this.defaultStyle = {};
            } else {
                this.defaultStyle = {
                    height: '90px',
                    backgroundColor: '#0F264D'
                };
            }
        });
    },
    methods: {
        scrollEvent() {
            if (document.getElementById('banner')) {
                if (document.documentElement.scrollTop >= 300) {
                    // document.getElementById('header').style.background = '#0f264d';

                    this.opacity = 1;
                } else {
                    let opacity = Number(
                        ((document.documentElement.scrollTop / 300) * (1 - this.defaultOpacity)).toFixed(2)
                    );
                    // document.getElementById('header').style.background = `rgba(15,38,77,${this.defaultOpacity +
                    //     opacity})`;
                    this.opacity = 0;
                }
            } else {
                document.getElementById('header').style.background = '#0f264d';
            }
            this.left = document.documentElement.scrollLeft;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    },
    components: {
        SearchInput,
        HeaderUser
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.header {
    // background-color: rgba(15, 38, 77, 0.3);
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    // width: 100%;
    transition: backgroundColor ease-in-out 0.3s;
    right: 0;
    min-width: 1200px;
    padding: 0;
}
.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    @include center-content(0.5rem, 19.2rem, 0.5rem);
}
.el-menu {
    background-color: transparent;
    margin-left: 0.3rem;
}

.el-menu.el-menu--horizontal.menu {
    border-bottom-width: 0;
}

.tip {
    flex-grow: 1;
    min-width: 0.1rem;
}

.btn1 {
    color: $--menu-item-font-color;
    &:hover {
        color: $--color-text-primary;
    }
}

.btn2 {
    color: $--color-text-primary;

    &:hover {
        color: mix($--color-black, $--color-text-primary, $--button-active-shade-percent);
    }
}

.btn3 {
    border-width: 0px;
    margin-right: 0.1rem;
    padding: 0 0.12rem;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
}

.iconfont {
    font-size: 32px;
}

.logo {
    width: 1.7rem;
    // height: 60px;
    flex-shrink: 0;
    display: block;
    cursor: pointer;
}
.el-button--danger.is-plain {
    background-color: #f56c6c20;
}
.el-button--warning.is-plain {
    background-color: rgba(255, 173, 51, 0.12);
}
</style>
<style lang="scss">
@import '../../styles/variables';
.defaaultHeader {
    z-index: 20;
    position: relative;
    .el-menu--horizontal > .el-menu-item {
        line-height: $--menu-item-height;
        height: $--menu-item-height;
        padding: 0 0.06rem;
        margin: 0 0.15rem;
        font-size: 14px;

        &:hover {
            color: $--color-warning !important;
        }

        &.is-active {
            a {
                color: $--color-warning !important;
            }
        }
    }
    .el-menu-item.is-active {
        // font-weight: bold;
        &:hover {
            color: #fff !important;
        }
    }
}
</style>
