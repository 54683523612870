import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import http from '../plugins/http';
import { MessageBox } from 'element-ui';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            title: 'imt-ke-ji-ping-tai-zhi-neng-zhi-zao-pin-pai-bai-ke-chan-pin-zi-dian',
            pageType: 'main'
        }
    },
    {
        path: '/brand',
        name: 'brand',
        component: () => import('../views/Brand.vue'),
        meta: {
            title: 'hui-yuan-zong-lan-imt-ke-ji-ping-tai',
            pageType: 'main'
        }
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/Product.vue'),
        meta: {
            title: 'quan-qiu-chan-pin-imt-ke-ji-ping-tai',
            pageType: 'main'
        }
    },
    {
        path: '/IMT',
        name: 'IMT',
        component: () => import('../views/IMT.vue'),
        meta: {
            title: 'imt-ping-tai-zhi-neng-zhi-zao-fu-wu-chan-ye-ping-tai',
            pageType: 'main'
        }
    },
    {
        path: '/',
        component: () => import('../views/news/NewsMain.vue'),
        children: [
            {
                path: '/NEWS',
                name: 'NEWS',
                component: () => import('../views/news/NewsList.vue'),
                meta: {
                    title: 'xin-xi-zi-xun-imt-ke-ji-ping-tai',
                    pageType: 'main'
                }
            },
            {
                path: '/NewsDetail',
                name: 'NewsDetail',
                component: () => import('../views/news/NewsDetail.vue'),
                meta: {
                    title: 'xin-wen-xiang-qing',
                    pageType: 'main'
                }
            }
        ]
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/Search.vue'),
        meta: {
            title: 'sou-suo-jie-guo'
        }
    },
    {
        path: '/rule',
        name: 'rule',
        component: () => import('../views/Rule.vue'),
        meta: {
            title: 'xie-yi'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/system/Register.vue'),
        meta: {
            title: 'xin-yong-hu-zhu-ce',
            HeaderType: 'system'
        }
    },
    {
        path: '/backPassword',
        name: 'backPassword',
        component: () => import('../views/system/BackPassword.vue'),
        meta: {
            title: 'wang-ji-mi-ma-1',
            HeaderType: 'system',
            FooterType: 'none'
        }
    },
    {
        path: '/manufacturersEntering',
        name: 'manufacturersEntering',
        component: () => import('../views/system/ManufacturersEntering.vue'),
        meta: {
            title: 'hui-yuan-ru-zhu',
            HeaderType: 'system'
        }
    },
    {
        path: '/',
        component: () => import('../views/system/EnterRule.vue'),
        children: [
            {
                path: '/enterRule1',
                name: 'enterRule1',
                component: () => import('../views/system/RuleStep1.vue'),
                meta: {
                    title: 'hui-yuan-ru-zhu',
                    HeaderType: 'system'
                }
            },
            {
                path: '/enterRule2',
                name: 'enterRule2',
                component: () => import('../views/system/RuleStep2.vue'),
                meta: {
                    title: 'hui-yuan-ru-zhu',
                    HeaderType: 'system'
                }
            },
            {
                path: '/enterRule3',
                name: 'enterRule3',
                component: () => import('../views/system/RuleStep3.vue'),
                meta: {
                    title: 'hui-yuan-ru-zhu',
                    HeaderType: 'system'
                }
            },
            {
                path: '/enterRule4',
                name: 'enterRule4',
                component: () => import('../views/system/RuleStep4.vue'),
                meta: {
                    title: 'hui-yuan-ru-zhu',
                    HeaderType: 'system'
                }
            }
        ]
    },
    {
        path: '/',
        component: () => import('../views/store/StoreMain.vue'),
        children: [
            {
                path: '/productDetail',
                name: 'productDetail',
                component: () => import('../views/store/ProductDetail.vue'),
                meta: {
                    title: 'chan-pin-xiang-qing',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            },
            {
                path: '/brandDetail',
                name: 'brandDetail',
                component: () => import('../views/store/BrandDetail.vue'),
                meta: {
                    title: 'hui-yuan-xiang-qing',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            },
            {
                path: '/caseDetail',
                name: 'caseDetail',
                component: () => import('../views/store/CaseDetail.vue'),
                meta: {
                    title: 'an-li-xiang-qing',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            },
            {
                path: '/brandNews',
                name: 'brandNews',
                component: () => import('../views/store/News.vue'),
                meta: {
                    title: 'xin-wen-zi-xun',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            },
            {
                path: '/brandNewsDetail',
                name: 'brandNewsDetail',
                component: () => import('../views/store/NewsDetail.vue'),
                meta: {
                    title: 'xin-wen-zi-xun',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            },
            {
                path: '/brandProductList',
                name: 'brandProductList',
                component: () => import('../views/store/Product.vue'),
                meta: {
                    title: 'quan-bu-chan-pin',
                    HeaderType: 'none',
                    pageType: 'store'
                }
            }
        ]
    },
    {
        path: '/compare',
        name: 'compare',
        component: () => import('../views/Compare.vue'),
        meta: {
            title: 'chan-pin-dui-bi-lie-biao',
            needLogin: true
        }
    },
    {
        path: '/',
        component: () => import('../views/mine/Base.vue'),
        children: [
            {
                path: '/accountInfo',
                name: 'accountInfo',
                component: () => import('../views/mine/AccountInfo.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/account/manage',
                name: 'accountManage',
                component: () => import('../views/account/Manage.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/accountInfo'
                }
            },
            {
                path: '/account/operating',
                name: 'accountOperating',
                component: () => import('../views/account/Operating.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/accountInfo'
                }
            },
            {
                path: '/account/changePassword',
                name: 'changePassword',
                component: () => import('../views/account/ChangePassword.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/accountInfo'
                }
            },
            {
                path: '/account/changePhone',
                name: 'changePhone',
                component: () => import('../views/account/ChangePhone.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/accountInfo'
                }
            },
            {
                path: '/account/changeEmail',
                name: 'changeEmail',
                component: () => import('../views/account/ChangeEmail.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/accountInfo'
                }
            },
            {
                path: '/mineCollection',
                name: 'mineCollection',
                component: () => import('../views/mine/Collection.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/mineActivity',
                name: 'mineActivity',
                component: () => import('../views/mine/Activity.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/mineActivityDetail',
                name: 'mineActivityDetail',
                component: () => import('../views/mine/ActivityDetail.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/mineActivity'
                }
            },
            {
                path: '/mineReadRecords',
                name: 'mineReadRecords',
                component: () => import('../views/mine/ReadRecords.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/mineOrder',
                name: 'mineOrder',
                component: () => import('../views/mine/Order.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/mineOrderEdit',
                name: 'mineOrderEdit',
                component: () => import('../views/mine/OrderEdit.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/mineOrder'
                }
            },
            {
                path: '/mineProductsTransactionEdit',
                name: 'mineProductsTransactionEdit',
                component: () => import('../views/mine/ProductsTransactionEdit.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/mineOrder'
                }
            },
            {
                path: '/minePoint',
                name: 'minePoint',
                component: () => import('../views/mine/Point.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendorDatas',
                name: 'vendorDatas',
                component: () => import('../views/vendor/Datas.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/mineVendorInfo',
                name: 'mineVendorInfo',
                component: () => import('../views/mine/VendorInfo.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendor/product',
                name: 'vendor/product',
                component: () => import('../views/vendor/Product.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendor/productEdit',
                name: 'vendor/productEdit',
                component: () => import('../views/vendor/ProductEdit.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/vendor/product'
                }
            },
            {
                path: '/vendor/customCategory',
                name: 'vendor/customCategory',
                component: () => import('../views/vendor/CustomCategory.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendor/case',
                name: 'vendor/case',
                component: () => import('../views/vendor/Case.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendor/News',
                name: 'vendor/News',
                component: () => import('../views/vendor/News.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vendor/caseEdit',
                name: 'vendor/caseEdit',
                component: () => import('../views/vendor/CaseEdit.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/vendor/case'
                }
            },
            {
                path: '/vendor/newsEdit',
                name: 'vendor/newsEdit',
                component: () => import('../views/vendor/NewsEdit.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true,
                    prePath: '/vendor/News'
                }
            },
            {
                path: '/vip/vipCenter',
                name: 'vip/vipCenter',
                component: () => import('../views/vip/VipCenter.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            },
            {
                path: '/vip/advertisement',
                name: 'vip/advertisement',
                component: () => import('../views/vip/Advertisement.vue'),
                meta: {
                    title: 'guan-li-zhong-xin',
                    FooterType: 'none',
                    needLogin: true
                }
            }
        ]
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../views/Chat.vue'),
        meta: {
            title: 'xiao-xi-zhong-xin',
            FooterType: 'none',
            needLogin: true
        }
    },
    {
        path: '/PDF',
        name: 'PDF',
        component: () => import('../views/PDF.vue'),
        meta: {
            title: 'pdf-yu-lan',
            HeaderType: 'none',
            FooterType: 'none',
            needLogin: false
        }
    },
    {
        path: '/vip/submit',
        name: 'vip/submit',
        component: () => import('../views/vip/Submit.vue'),
        meta: {
            title: 'que-ren-sheng-ji-hui-yuan-ding-dan',
            HeaderType: 'none',
            FooterType: 'none',
            needLogin: true
        }
    },
    {
        path: '/decorateShop',
        name: 'decorateShop',
        component: () => import('../views/vendor/DecorateShop.vue'),
        meta: {
            title: 'zhuang-xiu-dian-pu',
            HeaderType: 'none',
            FooterType: 'none',
            needLogin: true
        }
    },
    {
        path: '/502',
        name: '502',
        component: () => import('../views/system/502.vue'),
        meta: {
            title: 'wang-luo-cuo-wu',
            HeaderType: 'system',
            FooterType: 'none'
        }
    }
];

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.NODE_ENV === 'development' ? '/' : '/index',
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log(savedPosition);
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        }
    }
});
router.beforeEach((to, from, next) => {
    if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
    }
    if (/^\/http/.test(to.path)) {
        console.log(Vue.$baseUrl);
        let url = to.path.replace('/', '');
        let params = [];
        if (to.query) {
            for (let key in to.query) {
                if (to.query.hasOwnProperty(key)) {
                    params.push(`${key}=${to.query[key]}`);
                }
            }
        }
        if (params.length > 0) {
            url += `?${params.join('&')}`;
        }
        window.open(url);
        return;
    }
    if (!store.state.userInfo && from.path !== to.path && to.path !== '/502') {
        store
            .dispatch('getUserInfo')
            .then(() => {
                if (window.localStorage.getItem('lookNum')) {
                    window.localStorage.removeItem('lookNum');
                }
                store.commit('updateShowLogin', false);
                next();
            })
            .catch(() => {
                // window.localStorage.removeItem('imtPCtoken');\

                if (to.meta.pageType === 'store') {
                    next();
                    // let lookNum = window.localStorage.getItem('lookNum') || 0;
                    // if (lookNum < 5) {
                    //     lookNum++;
                    //     window.localStorage.setItem('lookNum', lookNum);
                    //     next();
                    // } else {
                    //     MessageBox.confirm('未登录用户只有五次查看权限，请先登录?', '提示', {
                    //         confirmButtonText: '立即登录',
                    //         cancelButtonText: '取消',
                    //         type: 'warning'
                    //     })
                    //         .then(() => {
                    //             store.commit('updateShowLogin', true);
                    //         })
                    //         .catch(() => {});
                    //     next(false);
                    // }
                } else if (to.meta.needLogin) {
                    store.commit('updateShowLogin', true);
                    next('/');
                } else {
                    next();
                }
            });
    } else if (!to.matched.length) {
        next('/404');
    } else {
        store.dispatch('userInit');
        store.commit('updateShowLogin', false);
        next();
    }
});

export default router;
