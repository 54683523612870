<template>
    <el-dialog
        :title="isVendor ? $t('zi-xun-hui-yuan') : $t('kuai-su-xun-jia')"
        :before-close="beforeClose"
        :visible="dialogVisible"
        width="600px"
        center
        append-to-body
        destroy-on-close
    >
        <el-form :model="form" ref="form" :rules="rules" label-width="175px">
            <el-form-item :label="$t('gan-xing-qu-de-chan-pin')" prop="productIds">
                <div class="input2">
                    <Product
                        ref="product"
                        :isOwn="false"
                        :disabled="!isVendor"
                        :vendorId="vendorInfoId"
                        v-model="form.productIds"
                        :chooseList="chooseList"
                    />
                </div>
            </el-form-item>
            <el-form-item
                :label="$t('imt-zhan-ting-xian-xia-ti-yan')"
                prop="offlineExperience"
                v-if="offlineExperience"
            >
                <el-radio-group v-model="form.offlineExperience">
                    <el-radio :label="true">
                        {{ $t('you-xing-qu-ke-yi-guo-qu') }}
                    </el-radio>
                    <el-radio :label="false">
                        {{ $t('hen-yi-han-bu-fang-bian-guo-qu') }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('ji-hua-jia-gong-de-gong-jian')" prop="artifact">
                <el-input
                    v-model="form.artifact"
                    :placeholder="isChinese ? $t('qing-miao-shu-nin-ji-hua-jia-gong-de-gong-jian') : $t('qing-shu-ru')"
                    class="input2"
                >
                </el-input>

                <div class="en-tips" v-if="!isChinese">{{ $t('qing-miao-shu-nin-ji-hua-jia-gong-de-gong-jian') }}</div>
            </el-form-item>

            <el-form-item :label="$t('nin-de-wen-ti')" prop="otherProblems">
                <el-input
                    type="textarea"
                    class="input2"
                    v-model="form.otherProblems"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    :maxlength="100"
                    show-word-limit
                    :placeholder="$t('qing-jian-yao-miao-shu-nin-de-wen-ti-xu-qiu-100-zi-yi-nei')"
                    resize="none"
                ></el-input>
            </el-form-item>

            <el-form-item lable-width="0px">
                <el-button type="warning" :loading="loading" size="small" @click="onSubmit">{{
                    $t('ti-jiao')
                }}</el-button>
                <el-button @click="close" size="small">{{ $t('qu-xiao') }}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import formPage from '../../mixins/formPage';
import Product from '../../components/select/Product.vue';
export default {
    name: 'Consult',
    mixins: [formPage],
    data() {
        return {
            dialogVisible: false,
            form: {
                vendorInfoId: 0,
                productIds: [],
                artifact: '',
                otherProblems: '',
                offlineExperience: '',
                userId: 0
            },
            rules: {
                productIds: {
                    required: true,
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value.length > 0) {
                            callback();
                        } else {
                            callback(new Error(this.$t('qing-xuan-ze-zi-xun-shang-pin')));
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapState(['userInfo', 'advisoryForm']),
        isVendor() {
            return !this.advisoryForm.chooseId;
        },
        vendorInfoId() {
            return this.advisoryForm.vendorInfoId || 0;
        },
        offlineExperience() {
            return this.advisoryForm.offlineExperience || false;
        },
        chooseList() {
            return this.vendorInfoId ? [] : this.advisoryForm.chooseList || [];
        }
    },
    watch: {
        'advisoryForm.show'() {
            this.dialogVisible = this.advisoryForm.show;
            if (this.dialogVisible) {
                this.$nextTick(() => {
                    this.$refs.product.getInfo();
                });
            }
        },
        advisoryForm() {
            console.log(this.advisoryForm);
            if (this.advisoryForm.chooseId) {
                this.form.productIds = this.vendorInfoId
                    ? [this.advisoryForm.chooseId]
                    : [...this.advisoryForm.chooseId];
            } else {
                this.form.productIds = [];
            }
        },
        $route() {
            this.$store.commit('updateAdvisoryForm', {
                show: false,
                vendorInfoId: 0,
                chooseId: '',
                offlineExperience: false,
                otherUserId: 0
            });
        }
    },
    components: {
        Product
    },
    methods: {
        beforeClose(done) {
            this.close();
            done();
        },
        close() {
            this.$store.commit('updateAdvisoryForm', {
                show: false,
                vendorInfoId: 0,
                chooseId: '',
                offlineExperience: false,
                otherUserId: 0
            });
        },
        submit() {
            var form = '';
            if (this.chooseList.length === 0) {
                const formData = { ...this.form };
                formData.vendorInfoId = this.vendorInfoId;
                formData.userId = this.userInfo.id;
                formData.offlineExperience = formData.offlineExperience || false;
                form = JSON.stringify([formData]);
            } else {
                form = JSON.stringify(
                    [...this.chooseList].map(item => {
                        return {
                            ...this.form,
                            productIds: [item.id],
                            vendorInfoId: item.vendorInfoId,
                            userId: this.userInfo.id
                        };
                    })
                );
            }

            this.$http
                .post('/advisoryForm/create', {
                    form: form
                })
                .then(res => {
                    this.$message.success(this.$t('ti-jiao-cheng-gong'));
                    this.close();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.el-button {
    min-width: 100px;
    height: 40px;
}
</style>
