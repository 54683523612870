<template>
    <el-dialog :visible.sync="dialogVisible" width="600px" center append-to-body :show-close="false" class="ruleStep4">
        <div slot="title" class="header">
            <img src="../../assets/img_ruzhutixing2x.jpg" alt="" />
        </div>
        <div class="center">
            {{ $t('gong-xi-nin-zheng-shi-ru-zhu-imt-ke-ji-ping-tai-zai-xian-zhan-ting') }}
            <br />
            {{ $t('kuai-qu-zhuang-xiu-nin-de-zhan-ting-wei-hu-nin-de-chan-pin-ba') }}
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="info" size="small" plain @click="dialogVisible = false">{{$t('shao-hou-zai-shuo')}}</el-button>
            <el-button type="warning" size="small" @click="(dialogVisible = false), $router.push('/decorateShop')"
                >{{$t('zhuang-xiu-dian-pu')}}</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'RuleStep4',
    data() {
        return {
            dialogVisible: false
        };
    },
    computed: {
        ...mapState(['showSuccess'])
    },
    watch: {
        showSuccess() {
            if (this.dialogVisible !== this.showSuccess) {
                this.dialogVisible = this.showSuccess;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.center {
    font-size: 14px;
    font-weight: bold;
    color: #292c33;
    line-height: 24px;
    text-align: center;
}

.el-button {
    min-width: 148px;
    height: 40px;
    border-width: 0px;
}

.header {
    img {
        width: 600px;
        display: bottom;
        height: auto;
    }
}
</style>

<style lang="scss">
.ruleStep4 {
    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__footer {
        padding-bottom: 50px;
    }
}
</style>
