//用户状态

import { mapState, mapActions } from 'vuex';
import contrast from './contrast';
import intention from './intention';
export default {
    mixins: [contrast, intention],
    methods: {
        goLogin() {
            this.$store.commit('updateShowLogin', true);
        },
        goRegister() {
            this.$router.push('/register');
        }
    },
    computed: {
        ...mapState(['userInfo', 'readInfo']),
        ...mapActions(['getNoReadNum'])
    }
};
