<template>
    <div class="single-upload">
        <el-upload
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="onSuccess"
            :before-upload="beforeUpload"
            accept="video/*"
        >
            <slot name="main">
                <div class="wrapper" :style="uploadStyle">
                    <!-- <img v-if="videoUrl" :src="videoUrl" class="upload-image" /> -->
                    <div class="hasInfo" v-if="videoUrl">
                        <!-- <i class="el-icon-video-camera"></i>
                        <span>{{ name }}</span> -->
                        <el-image :src="imgUrl">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-video-camera"></i>
                                <span>{{ name }}</span>
                            </div>
                        </el-image>
                        <div class="hover">
                            <el-tooltip class="item" effect="dark" :content="$t('she-zhi-feng-mian')" placement="top">
                                <i class="el-icon-picture" @click.stop="setImg"></i>
                            </el-tooltip>

                            <el-button type="danger" plain size="mini" @click.stop="del">
                                {{ $t('shan-chu') }}
                            </el-button>
                        </div>
                    </div>

                    <div class="single-uploader-icon" v-else>{{ $t('shang-chuan-shi-pin') }}</div>
                    <div v-if="loading" class="loading">
                        <i class="el-icon-loading"></i>
                    </div>
                    <img src="../assets/shangchuan_VIP.png" v-if="vipFunction" class="tips" alt="" />
                </div>
            </slot>
        </el-upload>
        <div id="upload-wrapper-video">
            <avatar-cropper
                ref="cropper"
                @submit="loading = true"
                @uploaded="handleUploaded"
                trigger="#upload-wrapper-video"
                :cropper-options="cropperOptions"
                :output-options="outputOptions"
                :output-quality="1"
                :upload-url="uploadUrl"
                :labels="{ submit: $t('que-ding'), cancel: $t('qu-xiao') }"
                :upload-headers="headers"
            />
        </div>
    </div>
</template>
<script>
import resolveUrl from 'resolve-url';
import AvatarCropper from 'vue-avatar-cropper';

export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        this.updateImageUrl(this.value);
    },
    props: {
        value: String,
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        usePrefix: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        ratio: {
            type: Array,
            default: [1, 1]
        },
        imgWidth: {
            type: Number,
            default: 100
        },
        size: {
            tupe: Number,
            // 单位M
            default: 50
        },
        vipFunction: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            videoUrl: '',
            loading: false,
            uploadUrl: '',
            hover: false,
            imgUrl: ''
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('imtPCtoken')
            };
        },
        outputOptions() {
            return { width: this.width * 2, height: this.height * 2 };
        },
        cropperOptions() {
            return {
                aspectRatio: Number((this.ratio[0] / this.ratio[1]).toFixed(2)),
                viewMode: 2,
                guides: false,
                modal: false,
                autoCropArea: 1
            };
        },
        uploadStyle() {
            return {
                width: this.imgWidth + 'px',
                height: (this.imgWidth * this.ratio[1]) / this.ratio[0] + 'px'
            };
        },
        name() {
            if (this.value) {
                return this.value.split('/').pop();
            } else {
                return '视频.mp4';
            }
        }
    },
    methods: {
        onSuccess(res, file) {
            this.loading = false;
            this.videoUrl = URL.createObjectURL(file.raw);
            var newVal = '';
            if (res instanceof Array) {
                newVal = res[0];
            } else {
                newVal = res;
            }
            this.$emit('input', newVal);
        },
        onError(err, file, fileList) {
            this.loading = false;
        },
        beforeUpload(file) {
            const isLt = file.size / 1024 / 1024 < this.size;

            if (!isLt) {
                this.$message.error(this.$t('shang-chuan-wen-jian-da-xiao-bu-neng-chao-guo') + this.size + ' MB!');
            } else {
                this.loading = true;
            }
            return isLt;
        },
        updateImageUrl(url) {
            this.videoUrl = url ? url.split(',')[0] : '';
            if (url && url.split(',').length > 0) {
                this.imgUrl = url.split(',')[1];
            }
        },
        del() {
            this.$emit('input', '');
        },
        setImg() {
            this.$refs.cropper.$refs.input.click();
        },
        handleUploaded(res) {
            this.loading = false;
            this.imgUrl = res;
            this.$emit('input', this.videoUrl + ',' + this.imgUrl);
            // this.src = res;
            //
        }
    },
    mounted() {
        document.body.appendChild(this.$refs.cropper.$el);
    },
    beforeDestroy() {
        document.body.removeChild(this.$refs.cropper.$el);
    },
    watch: {
        value(val) {
            this.updateImageUrl(val);
        }
    },
    components: {
        AvatarCropper
    }
};
</script>
<style lang="scss" scoped>
.single-uploader-icon {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bcc1cc;
    font-size: 12px;

    &:hover {
        border-color: #ffa526;
    }
}

.upload-image {
    width: 100%;
    height: 100%;
    display: block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        border-color: #409eff;
    }
}

.hasInfo {
    overflow: hidden;
    height: 100%;
    .el-image {
        height: 100%;
        width: 100%;

        .image-slot {
            height: 100%;

            i {
                font-size: 28px;
            }
            span {
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 10px;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }

    &:hover {
        .hover {
            display: flex;
        }
    }
}

.wrapper {
    position: relative;

    .tips {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 0px;
        right: -2px;
        z-index: 10;
    }
}

.single-upload .el-upload {
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    color: #333;
    font-size: 24px;
}

.hover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
        cursor: pointer;
        font-size: 20px;

        &:hover {
            color: #ffa526;
        }
    }
    display: none;

    .el-button {
        display: block;
        border-width: 0px;
        margin-top: 20px;
    }
}
</style>

<style lang="scss">
.hasInfo {
    overflow: hidden;
    height: 100%;
    .el-image {
        overflow: hidden;
        .image-slot {
            height: 100%;
            overflow: hidden;
            background-color: rgba(#ffa526, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            i {
                font-size: 28px;
            }
            span {
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 10px;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}
</style>
