<template>
    <el-dialog
        custom-class="login"
        :show-close="false"
        :visible.sync="loginVisible"
        width="960px"
        :before-close="dialogColse"
        destroy-on-close
        append-to-body
    >
        <div class="main">
            <div class="left">
                <auto-img :src="require('../assets/login_img_01.jpg')"></auto-img>
            </div>

            <div class="right">
                <div class="title">
                    {{ $t('zhang-hao-deng-lu') }}
                </div>
                <el-form
                    label-position="left"
                    hide-required-asterisk
                    :model="userInfo"
                    label-width="42px"
                    style="width: 350px;"
                    ref="form"
                    v-show="type === 'user'"
                    inline-message
                    :rules="userRule"
                >
                    <el-form-item class="input" prop="account">
                        <i class="el-icon-user" slot="label"></i>

                        <el-input
                            v-model="userInfo.account"
                            :placeholder="$t('qing-shu-ru-zhang-hao-you-xiang-shou-ji-hao')"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item class="input" prop="password">
                        <i class="el-icon-unlock" slot="label"></i>

                        <el-input
                            v-model="userInfo.password"
                            :placeholder="$t('qing-shu-ru-mi-ma')"
                            type="password"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <el-button class="bold" type="text" style="float: left;" @click="changeForm('phone')">
                            {{ $t('duan-xin-yan-zheng-ma-deng-lu') }}
                        </el-button>

                        <el-button
                            type="text"
                            style="float: right;"
                            @click="$router.push('/backPassword')"
                            class="color"
                        >
                            {{ $t('wang-ji-mi-ma') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item label-width="0" class="submit">
                        <el-button :loading="loading" @click="login" type="warning" style="width: 100%;">
                            {{ $t('deng-lu') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item label-width="0" class="next">
                        <el-button type="text" @click="$router.push('/register')" class="bold">
                            {{ $t('huan-mei-you-zhang-hao-li-ji-zhu-ce') }}
                        </el-button>
                    </el-form-item>
                </el-form>

                <el-form
                    v-show="type === 'phone'"
                    label-position="left"
                    hide-required-asterisk
                    :model="phoneInfo"
                    label-width="42px"
                    style="width: 350px;"
                    ref="form2"
                    :rules="phoneRules"
                    inline-message
                >
                    <el-form-item class="input" prop="phone">
                        <i class="el-icon-user" slot="label"></i>
                        <el-input v-model="phoneInfo.phone" :placeholder="$t('qing-shu-ru-shou-ji-hao')">
                            <el-select v-model="select" slot="prepend" :placeholder="$t('qing-xuan-ze')">
                                <el-option label="CH +86" value="+86"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="input" prop="code">
                        <i class="el-icon-tickets" slot="label"></i>

                        <div class="inputBtn">
                            <el-input v-model="phoneInfo.code" :placeholder="$t('qing-shu-ru-duan-xin-yan-zheng-ma')">
                            </el-input>

                            <el-button type="text" :disabled="isSend || !phoneInfo.phone" @click="send">
                                {{ isSend ? $t('yi-fa-song') + '(' + senNum + 'S)' : $t('fa-song-yan-zheng-ma') }}
                            </el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <el-button class="bold" type="text" style="float: left;" @click="changeForm('user')"
                            >{{ $t('zhang-hao-mi-ma-deng-lu') }}
                        </el-button>

                        <!-- <el-button
                            type="text"
                            style="float: right;"
                            @click="type = 'phone'"
                            class="color"
                            >忘记密码？
                        </el-button> -->
                    </el-form-item>
                    <el-form-item label-width="0" class="submit">
                        <el-button :loading="loading" @click="loginPhone" type="warning" style="width: 100%;"
                            >{{ $t('deng-lu') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item label-width="0" class="next">
                        <el-button type="text" @click="$router.push('/register')" class="bold"
                            >{{ $t('huan-mei-you-zhang-hao-li-ji-zhu-ce') }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { backPhone, verifySms, regular, sendMsg, countDown } from '../utils/Sms';
export default {
    name: 'Login',
    data() {
        return {
            loginVisible: false,
            userInfo: {
                account: '',
                password: ''
            },
            phoneInfo: {
                phone: '',
                code: ''
            },
            type: 'user',
            select: '+86',
            isSend: false,
            senNum: 60,
            phoneRules: {
                phone: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-shou-ji-hao-ma')));
                            }
                        }
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (regular.test('+86' + value)) {
                                this.$http
                                    .post('/auth/isPhoneReg', {
                                        phone: backPhone(value)
                                    })
                                    .then(res => {
                                        if (res) {
                                            callback();
                                        } else {
                                            callback(new Error(this.$t('gai-shou-ji-hao-wei-zhu-ce')));
                                        }
                                    });
                            } else {
                                callback(new Error(this.$t('shou-ji-hao-ge-shi-cuo-wu')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-duan-xin-yan-zheng-ma')));
                            }
                        }
                    }
                ]
            },
            userRule: {
                account: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-zhang-hao-you-xiang-shou-ji-hao')));
                            }
                        }
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-mi-ma')));
                            }
                        }
                    }
                ]
            },
            loading: false
        };
    },
    computed: {
        ...mapState(['showLogin'])
    },
    methods: {
        dialogColse(done) {
            this.$store.commit('updateShowLogin', false);
            done();
        },
        login() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post(
                            `/auth/${this.type == 'user' ? 'loginAccount' : 'phoneLogin'}`,
                            this.type === 'user' ? this.userInfo : this.phoneInfo
                        )
                        .then(res => {
                            window.localStorage.setItem('imtPCtoken', res);
                            return this.$store.dispatch('getUserInfo');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateShowLogin', false);
                            this.loginVisible = false;
                        })
                        .catch(e => {
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },

        send() {
            if (this.isSend) {
                return;
            }
            this.$refs.form2.validateField('phone', res => {
                if (!res) {
                    sendMsg(this.phoneInfo.phone, !this.isChinese).then(res => {
                        this.isSend = true;
                        this.senNum = 61;
                        this.setTime();
                    });
                }
            });
        },
        setTime() {
            this.senNum = countDown(this.senNum);
            if (this.senNum === null) {
                this.isSend = false;
                this.senNum = 60;
            } else {
                setTimeout(this.setTime, 1000);
            }
        },
        loginPhone() {
            this.$refs.form2.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/auth/phoneLogin', {
                            phone: this.phoneInfo.phone,
                            code: this.phoneInfo.code
                        })
                        .then(res => {
                            window.localStorage.setItem('imtPCtoken', res);
                            return this.$store.dispatch('getUserInfo');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateShowLogin', false);
                            this.loginVisible = false;
                        })
                        .catch(e => {
                            this.loading = false;
                            if (e) {
                                this.$message.error(e.error);
                            }
                        });
                }
            });
        },
        changeForm(type) {
            this.type = type;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';
.main {
    display: flex;
    .left {
        width: 480px;
        flex-shrink: 0;
    }

    .right {
        padding: 30px 75px;
        flex: 1;

        .title {
            font-size: 20px;
            font-weight: bold;
            color: #292c33;
            line-height: 28px;
            margin-bottom: 45px;
        }
        .el-form-item {
            margin-bottom: 0px;

            &.input {
                // border-bottom: 1px solid #f2f3f5;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    top: 50px;
                    height: 1px;
                    background-color: #f2f3f5;
                }
                margin-top: 20px;
            }

            &.submit {
                margin-top: 50px;
            }

            &.next {
                margin-top: 16px;

                .el-button {
                    display: block;
                    margin: auto;
                }
            }
        }

        .color {
            color: $--color-warning;
        }
    }
}

.inputBtn {
    display: flex;
    align-items: center;

    .el-input {
        flex-grow: 1;
    }
}

.el-form-item.is-error {
    .el-button {
        background-color: transparent;
    }
}
</style>

<style lang="scss">
.login {
    .el-dialog__header {
        padding: 0 0 0 0;
    }

    .el-dialog__body {
        padding: 0 0;
        .el-form-item__label {
            height: 50px;
            line-height: 50px;
            color: #000;
            font-size: 16px;
        }

        .el-input {
            .el-input__inner {
                height: 50px;
                line-height: 50px;
                border-width: 0px;
            }

            .el-input-group__prepend {
                background-color: transparent;
                width: 60px;
                color: #292c33;
                font-size: 14px;
                border-color: transparent;
            }
        }
    }
}
</style>
