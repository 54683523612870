//普通用户对店铺的操作

import userState from './UserState';
import { animateCSS } from '../utils/Animate';
export default {
    data() {
        return {
            //是否关注
            isCollection: false,
            collectId: 0,
            collectionType: '',
            cId: 0,
            needCollect: true
        };
    },
    watch: {
        cId() {
            if (
                this.$store.state.userInfo &&
                this.cId &&
                this.collectionType &&
                this.$store.state.userInfo.id &&
                this.needCollect
            ) {
                this.checkCollection();
            }
        }
    },
    mixins: [userState],
    methods: {
        // 关注店铺
        collection(e) {
            this.checkLogin()
                .then(() => {
                    animateCSS(e.currentTarget.getElementsByClassName('iconfont')[0].parentNode, 'pulse2');
                    if (this.isCollection) {
                        return this.$http.post('/collect/del/' + this.collectId);
                    } else {
                        return this.$http.post(
                            '/collect/save',
                            {
                                id: this.collectId || null,
                                userId: this.$store.state.userInfo.id,
                                cid: this.cId,
                                type: this.collectionType,
                                isCollect: true
                            },
                            { body: 'json' }
                        );
                    }
                })
                .then(res => {
                    if (res) {
                        this.collectId = res.id;
                        this.isCollection = res.isCollect;
                        this.$message.success(this.$t('guan-zhu-cheng-gong'));
                    } else {
                        this.collectId = 0;
                        this.isCollection = false;
                        this.$message.success(this.$t('qu-xiao-guan-zhu-cheng-gong'));
                    }
                })
                .catch(e => {
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        checkCollection() {
            this.$http
                .post(
                    '/collect/all',
                    {
                        query: {
                            userId: this.$store.state.userInfo.id,
                            cid: this.cId,
                            type: this.collectionType,
                            isCollect: true,
                            del: false
                        }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    if (res.content.length > 0) {
                        this.isCollection = res.content[0].isCollect;
                        this.collectId = res.content[0].id;
                    }
                });
        },
        //咨询&询价 [1,2]
        advisory(info, type = 'product') {
            this.checkLogin()
                .then(() => {
                    if (type === 'list') {
                        this.$store.commit('updateAdvisoryForm', {
                            show: true,
                            vendorInfoId: 0,
                            chooseId: [...info].map(item => {
                                return item.id;
                            }),
                            offlineExperience: true,
                            otherUserId: 0,
                            chooseList: [...info]
                        });
                    } else {
                        this.$store.commit('updateAdvisoryForm', {
                            show: true,
                            vendorInfoId: type === 'product' ? info.vendorInfoId : info.id,
                            chooseId: type === 'product' ? info.id : '',
                            offlineExperience: type === 'product' ? info.offlineExperience : false,
                            otherUserId: info.userId
                        });
                    }
                })
                .catch(e => {
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        //分享会员
        share() {
            this.checkLogin()
                .then(() => {})
                .catch(e => {
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        }
    }
};
