import axios from 'axios';
import store from '../store';
import router from '../router';
import qs from 'qs';
/* eslint-disable */
let baseUrl = 'http://localhost:8080';
switch (process.env.NODE_ENV) {
    case 'development':
        // baseUrl = 'http://localhost:8080';
        baseUrl = 'https://www.imttech.cn';
        break;
    case 'test':
        baseUrl = 'https://www.imttech.cn';
        break;
    case 'production':
        baseUrl = '../../';
        break;
}
const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use(
    function(config) {
        config.headers = config.headers || {};
        let token = window.localStorage.getItem('imtPCtoken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        let errorData = {};
        if (!error.response) {
            errorData = {
                error: '网络错误，请检查网络链接'
            };
            router.replace({
                name: '502'
            });
            // router.replace('/502')
        } else {
            errorData = error.response.data;
            if (401 === error.response.status) {
                if (router.currentRoute.meta.needLogin) {
                    store.commit('updateShowLogin', true);
                }
            }
        }
        return Promise.reject(errorData);
    }
);

const http = {
    get(url, params) {
        params = params || {};
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(
                    url,
                    {
                        params: params
                    },
                    { withCredentials: true }
                )
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    post(url, body, options) {
        options = options || {};
        body = body || {};
        if (!(body instanceof FormData)) {
            if (options.body !== 'json') {
                body = qs.stringify(body);
            }
        }
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(url, body, { withCredentials: true })
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
};
export default {
    axios: http,
    install(_Vue, options) {
        _Vue.prototype.$baseUrl = baseUrl;
        _Vue.prototype.$axios = axiosInstance;
        _Vue.prototype.$http = http;
    }
};
