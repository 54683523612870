<template>
    <div
        class="titleDriver"
        @click="goScroll"
    >
        <h3 class="fontYahei" :style="{ color: fontColor }">
            <slot name="title"></slot>
        </h3>
        <div class="divider"><slot name="sub"></slot></div>
    </div>
</template>
<script>
export default {
    name: 'TitleDriver',
    props: {
        fontColor: {
            type: String,
            default: '#292c33',
        },
    },
    methods: {
        goScroll() {
            	document.documentElement?.scrollTo({
                top:
                    this.$el instanceof HTMLElement
                        ? this.$el.offsetTop - 100
                        : 300,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.titleDriver {
    margin: 0 auto;
    cursor: pointer;

    h3 {
        font-size: 22px;
        font-weight: bold;
        color: $--color-text-regular;
        line-height: 32px;
        text-align: center;
        margin: 4px 0;
    }

    .divider {
        font-size: 12px;
        color: $--color-text-secondary;
        font-weight: 900;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            width: 30px;
            height: 1px;
            background-color: $--color-text-secondary;
            margin-right: 10px;
            transition: width ease-in-out 0.3s;
        }

        &::after {
            content: '';
            width: 30px;
            height: 1px;
            background-color: $--color-text-secondary;
            margin-left: 10px;
            transition: width ease-in-out 0.3s;
        }
    }

    &:hover {
        .divider {
            &::before {
                width: 50px;
            }

            &::after {
                width: 50px;
            }
        }
    }
}
</style>
