<template>
    <section>
        <el-upload
            list-type="picture-card"
            :action="uploadUrl"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="fileList"
            accept="image/*"
            multiple
            :limit="limit"
            :on-exceed="onExceed"
            :before-upload="beforeAvatarUpload"
        >
            <!-- <i class="el-icon-plus"></i> -->
            <span class="upload-text"> {{ $t('dian-ji-shang-chuan') }}</span>
            <div slot="tip" class="el-upload__tip">
                <slot></slot>
            </div>

            <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                    <div>
                        <div class="top">
                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"></i>
                            </span>

                            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                <i class="el-icon-delete"></i>
                            </span>
                        </div>

                        <div class="bottom">
                            <el-button
                                v-if="getIndex(file) !== 0"
                                type="warning"
                                size="mini"
                                plain
                                @click="move(file, -1)"
                                >{{ $t('shang-yi') }}
                            </el-button>
                            <el-button
                                v-if="getIndex(file) !== fileList.length - 1"
                                type="warning"
                                size="mini"
                                plain
                                @click="move(file, 1)"
                                >{{ $t('xia-yi') }}</el-button
                            >
                        </div>
                    </div>
                </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <el-image
            style="width: 0; height: 0; position: absolute"
            ref="preview"
            :src="previewUrl"
            :preview-src-list="previewList"
        >
        </el-image>
    </section>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        this.updateFileList(this.value);
    },
    props: {
        value: [Array, String],
        usePrefix: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        backString: {
            type: Boolean,
            default: false
        },
        size: {
            tupe: Number,
            // 单位M
            default: 1
        },
        limit: {
            type: Number,
            default() {
                return 10000;
            }
        }
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            uploadUrl: '',
            realFileList: [],
            previewUrl: '',
            previewList: []
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + window.localStorage.getItem('imtPCtoken')
            };
        }
    },
    methods: {
        getIndex(file) {
            return [...this.fileList].findIndex(item => {
                return item.value === file.value;
            });
        },
        handleRemove(file, _fileList) {
            console.log(file, _fileList);
            let fileList = _fileList || [...this.fileList];
            if (!_fileList) {
                const index = fileList.findIndex(item => {
                    return item.value === file.value;
                });
                fileList.splice(index, 1);
            }

            this.fileList = fileList;
            this.$emit('input', this.backString ? fileList.map(i => i.value).join(',') : fileList.map(i => i.value));
        },
        handlePictureCardPreview(file) {
            this.previewUrl = file.value;
            this.previewList = this.fileList.map(i => i.value);
            console.log(this.$refs.preview.$el);
            this.$refs.preview.showViewer = true;
        },
        handleSuccess(res, file, fileList) {
            if (res instanceof Array) {
                file.value = res[0];
            } else {
                file.value = res;
            }
            this.fileList = fileList;
            this.realFileList = fileList;
            this.$emit('input', this.backString ? fileList.map(i => i.value).join(',') : fileList.map(i => i.value));
        },
        updateFileList(list) {
            if (!list) {
                list = [];
            } else if (typeof list == 'string') {
                list = list.split(',');
            }
            for (let i = 0; i < list.length; i++) {
                if (!this.fileList[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                } else if (this.fileList[i].value !== list[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                }
            }

            this.fileList.splice(list.length);
            // this.fileList = list.map(i => {
            //     return {
            //         value: i,
            //         url: i,
            //     };
            // });
        },
        move(file, direction) {
            const fileList = [...this.fileList];
            console.log(fileList);
            const start = this.getIndex(file);
            const end = direction + start;
            const file1 = fileList[end];
            fileList.splice(start, 1, file1);
            fileList.splice(end, 1, file);
            this.fileList = fileList;
            this.$emit('input', this.backString ? fileList.map(i => i.value).join(',') : fileList.map(i => i.value));
        },
        beforeAvatarUpload(file) {
            console.log(file);
            const isLt = file.size / 1024 / 1024 < this.size;

            if (!isLt) {
                this.$message.error(this.$t('shang-chuan-wen-jian-da-xiao-bu-neng-chao-guo') + this.size + ' MB!');
            }
            return isLt;
        },
        onExceed(files, fileList) {
            console.log(files, fileList);
            this.$message.error(`最多上传${this.limit}个文件`);
        }
    },
    watch: {
        value(val, oldVal) {
            if (this.value && !this.fileList.length) {
                this.updateFileList(val);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.el-upload-list__item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}
.el-upload-list__item {
    border-radius: 0px !important;
}

.el-upload__tip {
    font-size: 12px;
    color: #bcc1cc;
    line-height: 22px;
}

.upload-text {
    font-size: 12px;
    color: #bcc1cc;
    line-height: 17px;
}
</style>

<style lang="scss" scoped>
.el-upload-list__item {
    border-radius: 0px !important;
}
</style>

<style lang="scss">
.el-image-viewer__btn.el-image-viewer__close {
    color: rgba($color: #fff, $alpha: 0.8);

    &:hover {
        color: #fff;
    }
}
.el-upload-list--picture-card .el-upload-list__item {
    margin: 0 20px 20px 0;
}
.el-upload--picture-card {
    background-color: #f5f7fa;
}

.el-upload-list {
    margin-bottom: 0px;
}
</style>
